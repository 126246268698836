var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Router } from "@uuip/unified-ui-platform";
import { STORE } from "@/store";
import { html } from "lit-html";
import { css, LitElement, customElement } from "lit-element";
import style from "./QueueStatsDetailsBlock.scss";
import config from "../config-queue-stats.json";
const CARD_VIEW_ACTION_EVENT_KEY = "card-view-action-clicked";
/**
 * @element agentx-queue-stats-widgets
 */
let AgentQueueStats = class AgentQueueStats extends LitElement {
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        this.addBindings();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeBindings();
    }
    addBindings() {
        window.addEventListener(CARD_VIEW_ACTION_EVENT_KEY, this.navigateInteractionTab);
    }
    removeBindings() {
        window.removeEventListener(CARD_VIEW_ACTION_EVENT_KEY, this.navigateInteractionTab);
    }
    navigateInteractionTab(e) {
        const customEvent = e;
        // eslint-disable-next-line no-console
        console.log("navigateInteractionTab", customEvent.detail.widget_id);
        STORE.routing.navigateInteractionTab();
    }
    render() {
        return html `
      <dynamic-area .area=${config} .isStationLoginSuccess=${STORE.app.isStationLoginSuccess}></dynamic-area>
    `;
    }
};
AgentQueueStats = __decorate([
    customElement("agentx-queue-stats-widgets")
], AgentQueueStats);
export { AgentQueueStats };
const QueueStatsDetailsBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <div class="queue-detail">
        <div>
          <agentx-wc-queue-stats-header></agentx-wc-queue-stats-header>
        </div>
        <div class="queue-detail-box">
          <agentx-wc-queue-stats-detail queueId="${STORE.routing.currentRouteValues.params.queueId}">
            <agentx-queue-stats-widgets class="queue-stats-widgets"></agentx-queue-stats-widgets>
          </agentx-wc-queue-stats-detail>
        </div>
        <div class="queue-detail-box">
          <agentx-wc-queue-stats-agents-view></agentx-wc-queue-stats-agents-view>
        </div>
      </div>
    `,
        styles: [
            style,
            css `
        .queue-detail {
          grid-area: queue-detail;
        }
      `
        ]
    });
};
export default QueueStatsDetailsBlock;
