import { Router } from "@uuip/unified-ui-platform";
import { html } from "lit-html";
import style from "./QueueStatsBlock.scss";
const QueueStatsBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <div class="queues-stats">
        <agentx-wc-queue-stats-landing class="queues-stats-landing"></agentx-wc-queue-stats-landing>
      </div>
    `,
        styles: [style]
    });
};
export default QueueStatsBlock;
