import { SYSTEM_ROLES, TOGGLE_MAXIMIZED_EVENT } from "@/app/components/constants";
import { retryAuthentication } from "@/app/integration/microsoft";
import { showBanner } from "@/app/utils/AlertBannerUtils";
import { isEmailOrChat, toggleDisplayOnMaximize } from "@/app/utils/Utils";
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { MediaType } from "@/store/store-agent-contact";
import "@agentx/agentx-mfe-wc-based";
import { SERVICE } from "@agentx/agentx-services";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html, nothing } from "lit-html";
import { toJS } from "mobx";
import style from "./InteractionControlBlock.scss";
const InteractionControlBlock = () => {
    var _a, _b, _c, _d;
    const mediaType = (_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.mediaType;
    const mediaChannel = (_b = STORE.agentContact.taskSelected) === null || _b === void 0 ? void 0 : _b.mediaChannel;
    const ariaLabel = mediaType === MediaType.Social ? mediaChannel : mediaType;
    let isMaximizedEventAttached = false;
    const isMaximizedFunction = () => {
        if (!isMaximizedEventAttached) {
            window.addEventListener(TOGGLE_MAXIMIZED_EVENT, (e) => {
                const customEvent = e;
                const value = toggleDisplayOnMaximize(customEvent.detail.isMaximized, "common-control");
                isMaximizedEventAttached = value;
            });
        }
    };
    if (isEmailOrChat((_c = STORE.agentContact) === null || _c === void 0 ? void 0 : _c.taskSelected)) {
        isMaximizedFunction();
    }
    return new Router.RouterView.Block({
        template: html `
      <div id="common-control" class="common-control">
        ${STORE.agentContact.taskSelected && STORE.agent.agentId
            ? html `
              <!-- Interaction control -->
              <div
                class="container"
                role="region"
                aria-label=${`${ariaLabel || ""} ${t("app:interaction:interactionControls")}`}
              >
                <agentx-react-interaction-control-wrapper
                  isDesktopConsultToEntryPointDnEnabled=${STORE.app.featureFlags.isDesktopConsultToEntryPointDnEnabled}
                  isAddressBookInfiniteScrollEnabled=${STORE.app.featureFlags.isAddressBookInfiniteScrollEnabled}
                  isDesktopConferenceRecordingEnabled=${STORE.app.featureFlags.isDesktopConferenceRecordingEnabled}
                  isDesktopAuxCodesInfiniteScrollEnabled=${STORE.app.featureFlags
                .isDesktopAuxCodesInfiniteScrollEnabled}
                  isDarkMode="${STORE.app.darkMode}"
                  .epDnMapping=${STORE.agent.getEpDnMapping}
                  .addressBook=${STORE.agent.getAddressBook}
                  .interaction="${STORE.agentContact.taskSelected}"
                  .isCallMuted="${STORE.agentContact.isCallMuted}"
                  .agentconfig="${{
                agentDBId: STORE.agent.agentDbId,
                channels: STORE.agent.channels,
                agentProfileID: STORE.agent.agentProfileID,
                isendcallenabled: STORE.agent.isEndCallEnabled,
                isEndConsultEnabled: STORE.agent.isEndConsultEnabled,
                privacyShieldVisible: STORE.agent.privacyShieldVisible,
                agentId: STORE.agent.agentId,
                callVariablesSuppressed: toJS(STORE.agent.callVariablesSuppressed),
                cadVariables: toJS(STORE.agent.cadVariables),
                allowConsultToQueue: STORE.agent.allowConsultToQueue,
                deviceType: STORE.agent.deviceType,
                dtmfValue: STORE.agent.dtmfValue,
                isWebRTCEnabled: (STORE.app.featureFlags.isWebRTCEnabled && STORE.agent.role === SYSTEM_ROLES.AGENT) ||
                    (STORE.app.featureFlags.isWebRTCEnabled &&
                        STORE.app.featureFlags.isWebRTCSupervisorEnabled &&
                        STORE.agent.role === SYSTEM_ROLES.SUPERVISOR),
                isIMIGlobalVariablesEnabled: STORE.app.featureFlags.isIMIGlobalVariablesEnabled &&
                    ((_d = STORE.agentContact.getMediaMgrFromTask()) === null || _d === void 0 ? void 0 : _d.includes("digitalmm")),
                isGlobalVariablesEnabled: STORE.app.featureFlags.isGlobalVariablesEnabled,
                role: STORE.agent.userSelectedRole,
                isBargeInEnabled: STORE.agent.isBargeInEnabled,
                agentCallMonitoringState: STORE.agentContact.getAgentCallMonitoringStateDetails,
                microsoftAuthConfig: STORE.auth.getMicrosoftAuthConfig,
                collaborationConfig: STORE.agent.collaboration,
                isWebrtcEndCallEnabled: STORE.app.featureFlags.isWebrtcEndCallEnabled,
                isWxccMultiPartyConfEnabled: STORE.app.featureFlags.isWxccMultiPartyConfEnabled,
                isWxccPersistCallEnabled: STORE.app.featureFlags.isWxccPersistCallEnabled,
                isWxccAgentInteractionEnabled: STORE.app.featureFlags.isWxccAgentInteractionEnabled,
                isAllowConfTransferToEpDnEnabled: STORE.app.featureFlags.isAllowConfTransferToEpDnEnabled,
                isInteractionBtnConfirmDialogEnabled: STORE.app.isInteractionBtnConfirmDialogEnabled,
                isConsultTransferInfiniteScrollEnabled: STORE.app.featureFlags.isConsultTransferInfiniteScrollEnabled,
                isSecureCADVariablesEnabled: STORE.app.featureFlags.isSecureCADVariablesEnabled,
                webexCallingCallId: STORE.agentContact.getWebexCallingCallId
            }}"
                  .cadStatus="${{
                isInteractionCADContainersExpanded: STORE.agentContact.getCADSectionOpenStatus,
                isMainContainerCADExpanded: STORE.agentContact.getMainCadSectionStatus,
                isConsultContainerCADExpanded: STORE.agentContact.getConsultCadSectionStatus
            }}"
                  .wrapUpData="${STORE.agent.wrapUpData}"
                  .agentWrapUpCodeProps="${STORE.agent.getAgentWrapUpCodeProps}"
                  .callpersistence="${{ isOpen: STORE.agentContact.callPersistence }}"
                  ?isVoiceInteraction="${STORE.agentContact.isTelephoneOrMidcall}"
                  .isVoiceIntPaneUpdatedByUser="${STORE.app.isVoiceIntPaneUpdatedByUser}"
                  .currentRoute="${STORE.routing.currentRouteName}"
                  @ax-update-uservoiceIntPaneState="${(e) => {
                STORE.app.updateUserVoiceIntPaneState(e.detail);
            }}"
                  isProgressiveCampaignEnabled="${STORE.app.featureFlags.isProgressiveCampaignEnabled}"
                  isProgressiveCampaign1NEnabled="${STORE.app.featureFlags.isProgressiveCampaign1NEnabled}"
                  @ax-interaction-wrapup="${(e) => {
                STORE.agent.updateStatusAfterOutdial(e);
                STORE.session.screenpop.updateScreenPopData(e.detail.interactionIdParam);
                STORE.session.interactionControl.removeInteraction(e.detail.interactionIdParam);
            }}"
                  @ax-main-cad-section-dimension="${(e) => {
                setTimeout(() => {
                    STORE.agentContact.updateCadSectionDimension("mainCallContainerHeight", e.detail.height);
                }, 0);
            }}"
                  @ax-consult-cad-section-dimension="${(e) => {
                setTimeout(() => {
                    STORE.agentContact.updateCadSectionDimension("consultCallContainerHeight", e.detail.height);
                }, 0);
            }}"
                  @ax-auto-wrap-up="${(e) => {
                STORE.session.interactionControl.removeInteraction(e.detail.interactionId);
            }}"
                  @ax-interaction-recording-pause-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-recording-resume-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-hold-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-resume-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-end-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-consult-transfer="${() => {
                STORE.agentContact.resetInteractionControlDimensions();
            }}"
                  @ax-interaction-consult-transfer-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-consult-end-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-conference-end-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-conference-exit-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-interaction-conference-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @ax-update-navigation-warning="${(e) => {
                STORE.agentContact.updateWarnOnNavigation(e.detail);
            }}"
                  @ax-reset-warn-on-navigation="${() => {
                STORE.agentContact.resetWarningOnNavigation();
            }}"
                  @ax-interaction-modal-toggled=${(e) => {
                STORE.common.updateInteractionModalState(e.detail);
            }}
                  @ax-interaction-fetch-epdn-list-success=${(e) => {
                STORE.agent.updateEpDnList(e.detail.data);
            }}
                  @ax-interaction-fetch-epdn-list-failure=${(e) => {
                STORE.agent.updatetrackingIdforEpList(e.detail.error);
            }}
                  @ax-interaction-fetch-addressbook-list-success=${(e) => {
                STORE.agent.updateAddressbookList(e.detail.data);
            }}
                  @ax-interaction-fetch-addressbook-list-failure=${(e) => {
                STORE.agent.updateAddressbookListFailed(e.detail);
            }}
                  @ax-interaction-monitoring-hold-failed="${(e) => {
                STORE.session.errorModal.setErrorDetails(e);
            }}"
                  @monitor-interaction-owner="${(e) => {
                STORE.agentContact.setMonitoringOwnerName(e.detail);
            }}"
                  @ax-update-dtmf-value="${(e) => {
                STORE.agent.updateDtmfValue(e.detail.value);
            }}"
                  @ax-fire-notification="${(e) => {
                STORE.generalNotifications.fireNotificationEvent(e.detail);
                STORE.browserNotifications.firebrowserNotification(e.detail.data);
            }}"
                  @ax-end-monitoring="${(e) => {
                STORE.agentContact.setIsMonitoringNotEndedManually(e.detail);
            }}"
                  @ax-banner-show="${(e) => {
                showBanner(e);
            }}"
                  @initiate-microsoft-auth-flow="${() => {
                retryAuthentication();
            }}"
                  @ax-wrapup-codes-update="${(e) => {
                STORE.agent.updateAgentWrapUpCodes(e.detail);
                SERVICE.conf.updateWrapUpCodeConfig(e.detail);
            }}"
                ></agentx-react-interaction-control-wrapper>
              </div>
            `
            : nothing}
      </div>
    `,
        styles: [
            style,
            css `
        /** Placeholder */
      `
        ]
    });
};
export default InteractionControlBlock;
