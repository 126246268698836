import AiAssistantBlock from "@/app/routing/shared-blocks/AiAssistantBlock";
import BannerBlock from "@/app/routing/shared-blocks/BannerBlock";
import InteractionControlBlock from "@/app/routing/shared-blocks/InteractionControlBlock";
import NavBlock from "@/app/routing/shared-blocks/NavBlock";
import TasksAreaBlock from "@/app/routing/shared-blocks/TasksAreaBlock";
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import AdvancedHeader from "../../shared-blocks/AdvancedHeaderBlock";
import PersistentAreaBlock from "../../shared-blocks/PersistentAreaBlock";
import PopoverBlock from "../../shared-blocks/PopoverBlock";
import UtilityBlock from "../../shared-blocks/UtilityBlock";
import style from "./BaseView.scss";
export const BaseView = () => {
    const blocks = {
        header: AdvancedHeader(),
        nav: NavBlock(),
        banner: BannerBlock(),
        tasks_area: TasksAreaBlock(),
        interaction_control: InteractionControlBlock(),
        persistent_area: PersistentAreaBlock(),
        popover_area: PopoverBlock(),
        utility_block: UtilityBlock()
    };
    if (STORE.app.featureFlags.isAiAssistantV2Enabled) {
        blocks.ai_assistant_area = AiAssistantBlock();
    }
    return new Router.RouterView.BlocksMap({
        blocks,
        styles: style
    });
};
