var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { customElement, html, LitElement } from "lit-element";
import { debounce, triggerSendDTMFErrorNotification, triggerToggleMuteErrorNotification } from "../utils/Utils";
import { INTERACTION_EVENTS, WEBEX_CALLING_EVENTS } from "./constants";
const logger = createLogger("WebexCallingWidget");
let WebexCallingWidget = class WebexCallingWidget extends LitElement {
    constructor() {
        super(...arguments);
        this.languageResources = "/i18n/{{lng}}/{{ns}}.json";
        this.fetchAndSetMuteStateForCurrentCall = () => {
            var _a;
            const activeCallId = (_a = STORE.agentContact.getWebexCallingCallId) !== null && _a !== void 0 ? _a : "";
            if (activeCallId && activeCallId !== "") {
                SERVICE.webexCalling
                    .getCallDetails({ callId: activeCallId })
                    .then((callDetails) => {
                    if (callDetails && callDetails.muted !== undefined && callDetails.muted !== STORE.agentContact.isCallMuted) {
                        this.setCallMuted(callDetails.muted);
                    }
                })
                    .catch((err) => {
                    var _a;
                    logger.error(`Error getting call details for callId: ${activeCallId}. trackingId: ${(_a = err === null || err === void 0 ? void 0 : err.details) === null || _a === void 0 ? void 0 : _a.trackingId}`);
                });
            }
        };
        this.sendDTMF = (e) => {
            if (e.detail && e.detail.callId && e.detail.callId !== "" && e.detail.dtmf && e.detail.dtmf !== "") {
                const callId = e.detail.callId;
                const dtmf = e.detail.dtmf;
                SERVICE.webexCalling.sendDTMF({ callId, dtmf }).catch((err) => {
                    var _a;
                    logger.error(`Error sending DTMF. trackingId: ${(_a = err === null || err === void 0 ? void 0 : err.details) === null || _a === void 0 ? void 0 : _a.trackingId}`);
                    triggerSendDTMFErrorNotification();
                });
            }
        };
        this.toggleMuteStateOfCall = (e) => {
            if (e.detail && e.detail.callId && e.detail.callId !== "") {
                const callId = e.detail.callId;
                if (STORE.agentContact.isCallMuted) {
                    this.setCallMuted(false);
                    SERVICE.webexCalling.unmuteCall({ callId }).catch((err) => {
                        var _a;
                        logger.error(`Error un-muting the call. trackingId: ${(_a = err === null || err === void 0 ? void 0 : err.details) === null || _a === void 0 ? void 0 : _a.trackingId}`);
                        triggerToggleMuteErrorNotification(false);
                        this.setCallMuted();
                    });
                }
                else {
                    this.setCallMuted();
                    SERVICE.webexCalling.muteCall({ callId }).catch((err) => {
                        var _a;
                        logger.error(`Error muting the call. trackingId: ${(_a = err === null || err === void 0 ? void 0 : err.details) === null || _a === void 0 ? void 0 : _a.trackingId}`);
                        triggerToggleMuteErrorNotification(true);
                        this.setCallMuted(false);
                    });
                }
            }
        };
        this.mercurySyncToggleMuteStateOfCall = (e) => {
            if (e.detail && e.detail.callId && e.detail.callId !== "" && e.detail.muted !== undefined) {
                const callIdForMuteChange = e.detail.callId;
                const isMuted = e.detail.muted;
                const activeCallId = STORE.agentContact.getWebexCallingCallId;
                if (activeCallId && callIdForMuteChange.endsWith(activeCallId) && STORE.agentContact.isCallMuted !== isMuted) {
                    this.setCallMuted(isMuted);
                }
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.registerCallEvents();
    }
    registerCallEvents() {
        window.addEventListener(WEBEX_CALLING_EVENTS.AX_WXC_ANSWERED, debounce(this.setCallMuted(false), 300));
        window.addEventListener(INTERACTION_EVENTS.AX_INTERACTION_DELETE, () => {
            this.setCallMuted(false);
        });
        window.addEventListener(INTERACTION_EVENTS.AX_SELECTED_INTERACTION_CHANGED, () => {
            this.fetchAndSetMuteStateForCurrentCall();
        });
        window.addEventListener(WEBEX_CALLING_EVENTS.AX_WXC_MUTE_UNMUTE, (e) => {
            this.toggleMuteStateOfCall(e);
        });
        window.addEventListener(WEBEX_CALLING_EVENTS.AX_WXC_MERC_SYNC_MUTE_UNMUTE, (e) => {
            this.mercurySyncToggleMuteStateOfCall(e);
        });
        window.addEventListener(WEBEX_CALLING_EVENTS.AX_WXC_SEND_DTMF, (e) => {
            this.sendDTMF(e);
        });
    }
    unRegisterCallEvents() {
        window.removeEventListener(WEBEX_CALLING_EVENTS.AX_WXC_ANSWERED, debounce(this.setCallMuted(false), 300));
        window.removeEventListener(INTERACTION_EVENTS.AX_INTERACTION_DELETE, () => {
            this.setCallMuted(false);
        });
        window.removeEventListener(INTERACTION_EVENTS.AX_SELECTED_INTERACTION_CHANGED, () => {
            this.fetchAndSetMuteStateForCurrentCall();
        });
        window.removeEventListener(WEBEX_CALLING_EVENTS.AX_WXC_MUTE_UNMUTE, (e) => {
            this.toggleMuteStateOfCall(e);
        });
        window.removeEventListener(WEBEX_CALLING_EVENTS.AX_WXC_SEND_DTMF, (e) => {
            this.sendDTMF(e);
        });
        window.removeEventListener(WEBEX_CALLING_EVENTS.AX_WXC_MERC_SYNC_MUTE_UNMUTE, (e) => {
            this.mercurySyncToggleMuteStateOfCall(e);
        });
    }
    disconnectedCallback() {
        this.unRegisterCallEvents();
    }
    setCallMuted(muted = true) {
        // this will set the call muted state in the store which has the effect of re-rendering the call control buttons
        STORE.agentContact.setIsCallMuted(muted);
    }
    render() {
        return html ` <div class="webex-call-container" hidden></div> `;
    }
};
WebexCallingWidget = __decorate([
    customElement("webex-calling-widget")
], WebexCallingWidget);
export { WebexCallingWidget };
