import { PRESENCE_STATUS, PRESENCE_STATUS_CALL_EVENTS, PRESENCE_SYNC, PRESENCE_SYNC_TTL, PRESENCE_SYNC_THRESHOLD } from "@/app/components/constants";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { DateTime } from "luxon";
import { debounce, handleTelemetryOnPresenceChange, logger } from "../Utils";
import { displayBanner, getIdleCode } from "./UtilsSyncState";
export class StateSyncManager {
    constructor() {
        this.syncStatus = "unknown";
        this.handlePresenceStatusEvents = (e) => {
            var _a;
            logger.info("[PresenceStateSync]: Handle Presence Sync State update event");
            const wsWriteTimestamp = e === null || e === void 0 ? void 0 : e.wsWriteTimestamp;
            if (wsWriteTimestamp) {
                const wsTime = DateTime.fromMillis(Number(wsWriteTimestamp));
                const currentTime = DateTime.fromMillis(Date.now());
                const differenceInSeconds = (_a = currentTime.diff(wsTime, "seconds")) === null || _a === void 0 ? void 0 : _a.seconds;
                if (differenceInSeconds > PRESENCE_SYNC_THRESHOLD) {
                    const collabType = STORE.agent.collabToolInSync;
                    logger.error(`[PresenceStateSync]: event=PresenceSyncDelay | Type=${collabType} | Duration=${differenceInSeconds}`);
                }
            }
            if ((STORE.agentContact.isActiveCall && e.data.status === PRESENCE_STATUS.call) ||
                this.syncStatus === e.data.status // handle the stuck state which keep pushing the same status.
            ) {
                logger.info("[PresenceStateSync]: Presence Sync StateChange suppressed");
                this.syncStatus = e.data.status;
                return;
            }
            this.syncStatus = e.data.status;
            const idleCode = getIdleCode(this.syncStatus, e.data.meetingType);
            logger.info("[PresenceStateSync]: idleCode:", idleCode);
            if (idleCode && idleCode !== "" && !this.isSyncStateSuppress(idleCode)) {
                const newState = idleCode === SERVICE.constants.AgentState.AvailableAuxCodeId
                    ? SERVICE.constants.AgentState.Available
                    : SERVICE.constants.AgentState.Idle;
                STORE.agent.updateStateByPresence(idleCode, newState);
                handleTelemetryOnPresenceChange();
                logger.info("[PresenceStateSync]: Presence Sync StateChange triggered");
            }
            else {
                logger.info("[PresenceStateSync]: Presence Sync StateChange suppressed");
            }
        };
        this.repeatUpdateCallPresenceStatus = () => {
            this.updateCallPresenceStatus();
            /*
            TTL minus 60 seconds ensures that we make the subsequent call to update the status before the previous TTL expires.
            60 seconds was chosen to ensure that potential intensive timer throttling won't result in a presence flicker.
            */
            this.callPresenceInterval = window.setInterval(this.updateCallPresenceStatus, (PRESENCE_SYNC_TTL - 60) * 1000);
        };
        this.resetPresenceStatus = () => {
            logger.info("[PresenceSync]: update presence on call completion");
            this.clearCallPresenceInterval();
            SERVICE.webex.setStatus(PRESENCE_STATUS.call, 0);
        };
        this.handlePreseceStateUpdate = (e) => {
            STORE.agent.updateLastStateChangeByPresence(e.data.lastStateChangeReason === PRESENCE_SYNC);
            if (e.data.lastStateChangeReason === PRESENCE_SYNC) {
                displayBanner(e.data.subStatus);
            }
        };
    }
    // returns true to suppress presence state sync to update.
    isSyncStateSuppress(idleCode) {
        if (STORE.agent.subStatus !== SERVICE.constants.AgentState.Available) {
            return !(STORE.agent.lastStateChangeByPresence && idleCode === SERVICE.constants.AgentState.AvailableAuxCodeId);
        }
        else if (STORE.agent.subStatus === SERVICE.constants.AgentState.Available &&
            idleCode !== SERVICE.constants.AgentState.AvailableAuxCodeId) {
            return false;
        }
        return true;
    }
    updateCallPresenceStatus() {
        logger.info(`[PresenceSync]: update presence on call connected with status:  ${PRESENCE_STATUS.call}`);
        SERVICE.webex.setStatus(PRESENCE_STATUS.call, PRESENCE_SYNC_TTL);
    }
    clearCallPresenceInterval() {
        if (this.callPresenceInterval) {
            window.clearInterval(this.callPresenceInterval);
            this.callPresenceInterval = undefined;
        }
    }
    static getCurrentPresenceStatus() {
        SERVICE.webex.getPresenceStatus(STORE.agent.agentId);
    }
    register() {
        logger.info("[PresenceStateSync]: Presence Sync State Registered to handlePresenceStatusEvents");
        SERVICE.webex.onNewPresenceStateSyncEvent.listen(debounce((event) => {
            var _a;
            logger.info("[PresenceStateSync]: event state update with status: ", event.data.status);
            ((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.meetingType) &&
                logger.info("[PresenceStateSync]: event state update with meetingType: ", event.data.meetingType);
            this.handlePresenceStatusEvents(event);
        }, 500));
        SERVICE.aqm.agent.eAgentStateChangeSuccess.listen((data) => {
            this.handlePreseceStateUpdate(data);
        });
        window.addEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_CREATE, this.repeatUpdateCallPresenceStatus);
        window.addEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_DELETE, this.resetPresenceStatus);
        StateSyncManager.getCurrentPresenceStatus();
        return () => {
            this.clearCallPresenceInterval();
            window.removeEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_CREATE, this.repeatUpdateCallPresenceStatus);
            window.removeEventListener(PRESENCE_STATUS_CALL_EVENTS.CALL_DELETE, this.resetPresenceStatus);
        };
    }
}
