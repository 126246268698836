import { BaseView } from "@/app/routing/views/_base-view/BaseView";
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import style from "./ApsView.scss";
import ApsReportsBlock from "./blocks/ApsReportsBlock";
export const ApsView = () => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: {
            aps_reports: ApsReportsBlock()
        },
        styles: [
            style,
            STORE.dynamic.showPersistentWidgets
                ? css `
            :host {
              grid-template-areas:
                "header header header header header "
                "nav banner banner banner ai"
                "nav tasks common-control common-control ai"
                "nav tasks persistent-page-control persistent-page-control ai"
                "nav tasks aps-reports aps-reports ai";
              grid-template-columns: auto auto auto 1fr auto;
              grid-template-rows: auto auto auto 1fr 1fr auto;
            }
          `
                : css `
            :host {
              grid-template-areas:
                "header header header header header"
                "nav banner banner banner ai"
                "nav tasks common-control common-control ai"
                "nav tasks aps-reports aps-reports ai"
                "nav tasks aps-reports aps-reports ai";
              grid-template-columns: auto auto auto 1fr auto;
              grid-template-rows: auto auto auto 1fr 1fr auto;
            }
          `
        ]
    });
};
