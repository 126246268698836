import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html } from "lit-html";
import style from "./AiAssistantBlock.scss";
const AiAssistantBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <!-- AiAssistant -->
      <div id="aiAssistantView" class="aiAssistantWrapper ${STORE.app.aiBlockCollapsed ? "hide" : ""} "> 
          <agentx-wc-collapse-area
            .direction="${"right"}"
            .forceTogglerVisibilityOnMouseOverSelectors="${`agentx-wc-navigation`}"
            @toggle="${() => STORE.app.toggleAiAssistantPanelCollapse()}"
          >
            <div class="aiAssistantArea">
              <div slot="header" class="aiAssistantHeader"> 
                <div tabindex="1" aria-label=${t("app:common.CiscoAiAssistant")}>
                  ${t("app:common.CiscoAiAssistant")}
                </div>
                <md-button
                  color="color-none"
                  class="closeButton"
                  circle
                  @click=${() => STORE.app.toggleAiAssistantPanelCollapse()}
                  aria-label="Close"
                >
                <md-icon name="cancel-bold" size="14" iconSet="momentumDesign"></md-icon> 
                </md-button>
              </div>
            </div>
          </agentx-wc-collapse-area>
        </div>
      </div>
    `,
        styles: [
            style,
            css `
        .aiAssistantArea {
          grid-area: ai; /*grid*/
        }

        .hide {
          display: none;
          transform: translateX(100%);
          transition: transform 0.3s ease-in-out;
        }
      `
        ]
    });
};
export default AiAssistantBlock;
