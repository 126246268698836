var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import axios from "axios";
import { fetchTeamDataById, getLayoutFromLS, updateLSLayout } from "./Utils";
const logger = createLogger("[DesktopLayoutUtils]");
const LAYOUT_CONFIG_CACHE_KEY = "agentx-desktop-layout-config";
export const UserRoles = {
    AGENT: "agent",
    SUPERVISOR: "supervisor",
    SUPERVISOR_AGENT: "agent_supervisor"
};
export const LayoutUserRoleKeys = {
    AGENT: "agent",
    SUPERVISOR: "supervisor",
    SUPERVISOR_AGENT: "supervisorAgent"
};
export const isAgent = (role) => {
    return role === LayoutUserRoleKeys.AGENT;
};
export const isSupervisor = (role) => {
    return role === LayoutUserRoleKeys.SUPERVISOR;
};
export const isSupervisorAgent = (role) => {
    return role === LayoutUserRoleKeys.SUPERVISOR_AGENT;
};
export const isLayoutJsonInOldStrcuture = (data) => {
    return data.area;
};
export const getLayoutForUserRole = (data, role) => {
    if (isLayoutJsonInOldStrcuture(data)) {
        return data;
    }
    return data[role] ? data[role] : undefined;
};
const getIsRoleBasedLayout = (data, role) => {
    return Object.prototype.hasOwnProperty.call(data, role);
};
export const updateLayoutConfigCache = (data) => {
    return updateLSLayout(data, LAYOUT_CONFIG_CACHE_KEY);
};
export const getLayoutConfigFromCache = () => {
    return getLayoutFromLS(LAYOUT_CONFIG_CACHE_KEY);
};
export const getLayoutFromAPIGateway = (attributes) => {
    if (!attributes) {
        return undefined;
    }
    //eslint-disable-next-line sonarjs/prefer-object-spread
    const data = Object.assign({}, JSON.parse(attributes.jsonFileContent));
    const roleSpecificLayout = getLayoutForUserRole(data, STORE.agent.getUserRole);
    if (roleSpecificLayout) {
        roleSpecificLayout.layoutName = attributes.name;
        roleSpecificLayout.isRoleBasedLayout = getIsRoleBasedLayout(data, STORE.agent.getUserRole);
    }
    return [roleSpecificLayout, data];
};
const fetchTeamDataByPage = (orgId, agentDbId, teamId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchTeamDataById(orgId, 0, agentDbId, "", teamId);
    return response ? response.data : [];
});
const getSelectedDesktopLayoutIdByTeam = (profile, selectTeamDbId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    let auxData = profile.teams.filter((auxData) => selectTeamDbId === auxData.teamId);
    /** case when selected team is not present in config response , as it has only 1st page team response */
    if (auxData && auxData.length === 0 && STORE.app.featureFlags.isConsultTransferInfiniteScrollEnabled) {
        auxData = yield fetchTeamDataByPage(profile.orgId, profile.analyserUserId || profile.agentDbId, selectTeamDbId);
    }
    if (auxData && auxData.length > 0) {
        return ((_a = auxData[0]) === null || _a === void 0 ? void 0 : _a.desktopLayoutId) || "";
    }
    if (auxData && auxData.length === 0 && isSupervisor(STORE.agent.getUserRole)) {
        // This block will be executed, If Supervisor has no team assigned, only primary teams are assigned.
        try {
            const team = yield SERVICE.cms.fetchTeamsById({
                orgId: profile.orgId,
                teamId: selectTeamDbId
            });
            logger.info(`event=fetchTeamsById success:  ${JSON.stringify(team)} for orgId ${profile.orgId} and teamId: ${selectTeamDbId}`);
            return team && team.desktopLayoutId ? team.desktopLayoutId : "";
        }
        catch (error) {
            logger.error(`event=fetchTeamsById error:  ${JSON.stringify(error)} for orgId ${profile.orgId} and teamId: ${selectTeamDbId}`);
            return "";
        }
    }
    return undefined;
});
const updateHeadless = (data) => {
    if (data.area && data.area.headless) {
        STORE.dynamic.updateHeadless(data.area.headless);
    }
};
const updateNavigation = (data) => {
    if (data.area && data.area.navigation) {
        STORE.dynamic.updateNavigation(data.area.navigation);
    }
};
const updateHomePage = (data) => {
    if (data.area && data.area.homepage) {
        STORE.dynamic.updateHomePage(data.area.homepage);
    }
    else {
        STORE.dynamic.updateHomePage(null);
    }
};
const updateWidgetExplorer = (data) => {
    if (data.area && data.area.widgetExplorer) {
        STORE.dynamic.updateWidgetExplorer(data.area.widgetExplorer);
    }
};
const updatePanel = (data) => {
    if (data.area && data.area.panel) {
        STORE.dynamic.updatePanel(data.area.panel);
    }
};
const updatePersistent = (data) => {
    if (data.area && data.area.persistent) {
        STORE.dynamic.updatePersistent(data.area.persistent);
    }
    else {
        STORE.dynamic.updatePersistent([]);
    }
};
const updateHeader = (data) => {
    if (data.area && data.area.header) {
        STORE.dynamic.updateHeader(data.area.header);
    }
    else {
        STORE.dynamic.updateHeader(null);
    }
};
const advancedHeader = (data) => {
    if (data.area && data.area.advancedHeader) {
        STORE.dynamic.updateAdvancedHeader(data.area.advancedHeader);
    }
    else {
        STORE.dynamic.updateAdvancedHeader(null);
    }
};
const updateActionIcons = (data) => {
    if (data.headerActions && Array.isArray(data.headerActions)) {
        STORE.dynamic.updateActionIcons(data.headerActions);
    }
};
const updateTaskPageIllustration = (data) => {
    var _a;
    if (((_a = data.taskPageIllustration) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== "no-illustration") {
        const taskPageIllustration = data.taskPageIllustration || "/images/illustrations/support-contact-320-cobalt-lime.svg";
        STORE.app.updateTaskPageIllustration(taskPageIllustration);
    }
};
const updateAppLogo = (data) => {
    if (data.logo && data.logo.length > 0) {
        STORE.app.updateLogo(data.logo);
    }
    else {
        logger.warn("Logo is missing in desktop layout");
        STORE.app.updateLogo("");
    }
};
const updateAppTitle = (data) => {
    if (data.appTitle && data.appTitle.length > 0) {
        STORE.app.updateTitle(data.appTitle);
    }
    else {
        logger.warn("App title is missing in desktop layout");
        STORE.app.updateTitle("");
    }
};
const updateWXMConfigured = (data) => {
    STORE.app.updateWxmEnabled(!!data.wxmConfigured);
};
const updateWebexConfigured = (data) => {
    var _a;
    STORE.app.updateWebexEnabled(((_a = data.desktopChatApp) === null || _a === void 0 ? void 0 : _a.webexConfigured) === true ? true : false);
};
const updateDragDropFlag = (data) => {
    var _a;
    STORE.dynamic.updateIsDragDrop((_a = data.dragDropEnabled) !== null && _a !== void 0 ? _a : false);
};
const updateBrowserNotificationTimeout = (data) => {
    STORE.browserNotifications.setNotificationTimeout(data.browserNotificationTimer ? data.browserNotificationTimer : 8);
};
const updateStopNavigateOnAcceptTask = (data) => {
    STORE.app.updateStopNavigateOnAcceptTask(data.stopNavigateOnAcceptTask ? data.stopNavigateOnAcceptTask : false);
};
const updateCollapseVoiceIntPane = (data) => {
    STORE.app.updateCollapseVoiceIntPane(data.collapseVoiceIntPane ? data.collapseVoiceIntPane : false);
};
export const updateStoresWithLayoutDetails = (layoutConfig, desktopLayoutJson) => {
    if (layoutConfig) {
        STORE.dynamic.resetResponsiveLayout();
        updateAppTitle(layoutConfig);
        updateAppLogo(layoutConfig);
        updateHeader(layoutConfig);
        advancedHeader(layoutConfig);
        updateStopNavigateOnAcceptTask(layoutConfig);
        updateCollapseVoiceIntPane(layoutConfig);
        updatePanel(layoutConfig);
        updatePersistent(layoutConfig);
        updateNavigation(layoutConfig);
        updateHomePage(layoutConfig);
        updateWidgetExplorer(layoutConfig);
        updateHeadless(layoutConfig);
        updateDragDropFlag(layoutConfig);
        updateLayoutConfigCache(desktopLayoutJson);
        updateWXMConfigured(layoutConfig);
        updateBrowserNotificationTimeout(layoutConfig);
        updateWebexConfigured(layoutConfig);
        updateActionIcons(layoutConfig);
        updateTaskPageIllustration(layoutConfig);
        STORE.layout.updateDesktopLayoutLoaded(true);
        STORE.generalNotifications.updateConfig(layoutConfig.notificationTimer, layoutConfig.maximumNotificationCount);
    }
};
export const fetchLocalDesktopLayout = () => __awaiter(void 0, void 0, void 0, function* () {
    const { data } = yield axios.get(process.env.DYNAMIC_WIDGETS);
    logger.info(`event=fetchLocalDesktopLayout | Found ${STORE.agent.getUserRole} role From Default Layout`);
    return [getLayoutForUserRole(data, STORE.agent.getUserRole), data];
});
const fetchGlobalDesktopLayoutV2 = (orgId) => __awaiter(void 0, void 0, void 0, function* () {
    const layoutResp = yield SERVICE.cms.fetchGlobalDesktopLayoutV2({
        orgId
    });
    if (layoutResp[0]) {
        logger.info(`event=fetchGlobalDesktopLayout | Org Level Global Desktop layout Fetch Success ${layoutResp[0]}`);
        return layoutResp[0];
    }
    return undefined;
});
const fetchDesktopLayoutByTeam = (orgId, teamId, desktopLayoutId) => __awaiter(void 0, void 0, void 0, function* () {
    const layoutResp = yield SERVICE.cms.fetchDesktopLayout({
        orgId,
        desktopLayoutId
    });
    //eslint-disable-next-line sonarjs/prefer-object-spread
    const formatLog = Object.assign({}, layoutResp, {
        jsonFileContent: JSON.parse(layoutResp.jsonFileContent)
    });
    logger.info(`event=fetchDesktopLayoutByTeam | Desktop layout Fetch Success for the team[${teamId}] -> `, formatLog);
    return layoutResp;
});
const fetchOrgLevelGlobalDesktop = (orgId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const attributes = yield fetchGlobalDesktopLayoutV2(orgId);
        const roleSpecificLayout = yield getLayoutFromAPIGateway(attributes);
        if (roleSpecificLayout && roleSpecificLayout[0]) {
            logger.info(`event=fetchOrgLevelGlobalDesktop| Found ${STORE.agent.getUserRole} Layout in Org Level Global Desktop Layout`);
            return roleSpecificLayout;
        }
        else {
            logger.info(`event=fetchOrgLevelGlobalDesktop | Not Found ${STORE.agent.getUserRole} Layout in Org Level Global Desktop Layout`);
        }
    }
    catch (e) {
        logger.error("event=fetchOrgGlobalDesktopFailed | Loading Local layout", e);
        return fetchLocalDesktopLayout();
    }
    return undefined;
});
const fetchDesktopLayoutFromTeam = (profile, teamId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        logger.info(`event=fetchDesktopLayoutByTeam | teamId is ${teamId}`);
        if (teamId) {
            let attributes;
            const desktopLayoutId = yield getSelectedDesktopLayoutIdByTeam(profile, teamId);
            if (desktopLayoutId) {
                attributes = yield fetchDesktopLayoutByTeam(profile.orgId, teamId, desktopLayoutId);
                const roleSpecificLayout = getLayoutFromAPIGateway(attributes);
                if (roleSpecificLayout && roleSpecificLayout[0]) {
                    logger.info(`event=fetchDesktopLayoutByTeam | Found ${STORE.agent.getUserRole} Layout From team associated DesktopLayout`);
                    return roleSpecificLayout;
                }
                else {
                    logger.info(`event=fetchDesktopLayoutByTeam | Not Found ${STORE.agent.getUserRole} Layout in team associated DesktopLayout`);
                }
            }
        }
    }
    catch (e) {
        logger.error("event=fetchDesktopLayoutByTeamFailed | Loading Org Level Global Layout", e);
        return fetchOrgLevelGlobalDesktop(profile.orgId);
    }
    return undefined;
});
const processCachedLayoutJson = (cachedLayoutConfig, agentXAppRouting) => __awaiter(void 0, void 0, void 0, function* () {
    if (cachedLayoutConfig) {
        const { layout, userSelectedRole } = cachedLayoutConfig;
        logger.info("Updating desktop layout from cache");
        if (userSelectedRole in layout) {
            updateStoresWithLayoutDetails(layout[userSelectedRole], layout);
        }
        else if (isAgent(userSelectedRole)) {
            updateStoresWithLayoutDetails(layout, layout);
        }
        agentXAppRouting && agentXAppRouting.addOrUpdateDynamicRoutes();
    }
});
const trackCustomLayoutFailEvent = (reason) => {
    var _a, _b;
    SERVICE.telemetry.track(SERVICE.telemetry.TELEM_EVENT.CUSTOM_LAYOUT_LOAD_FAIL, {
        Reason: reason
    }, [(_b = (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.SERVICE_PROVIDERS) === null || _b === void 0 ? void 0 : _b.prometheus]);
};
const processJSONLayout = (profile, teamId, cachedLayoutConfig, agentXAppRouting) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c;
    SERVICE.telemetry.timeEvent(SERVICE.telemetry.TELEM_EVENT.CUSTOM_LAYOUT_LOAD_FAIL, [
        (_c = (_b = SERVICE.telemetry) === null || _b === void 0 ? void 0 : _b.SERVICE_PROVIDERS) === null || _c === void 0 ? void 0 : _c.prometheus
    ]);
    let layoutType = "teamLayout";
    let isDesktopLayoutFound = yield fetchDesktopLayoutFromTeam(profile, teamId);
    if (!isDesktopLayoutFound || isDesktopLayoutFound[0] === undefined) {
        isDesktopLayoutFound = yield fetchOrgLevelGlobalDesktop(profile.orgId);
        layoutType = "globalLayout";
    }
    if (!isDesktopLayoutFound || isDesktopLayoutFound[0] === undefined) {
        trackCustomLayoutFailEvent("NoCustomLayoutFound");
        isDesktopLayoutFound = yield fetchLocalDesktopLayout();
        layoutType = "defaultLayout";
    }
    if (isDesktopLayoutFound && isDesktopLayoutFound[0]) {
        logger.info("Updating desktop layout from server");
        const stopNavigateOnAcceptTask = isDesktopLayoutFound[0].stopNavigateOnAcceptTask === undefined
            ? "undefined"
            : isDesktopLayoutFound[0].stopNavigateOnAcceptTask;
        SERVICE.telemetry.track(SERVICE.telemetry.TELEM_EVENT.LAYOUT_FETCHED_FROM_SERVER, {
            [SERVICE.telemetry.TELEM_PROPS.STOP_NAVIGATE_ON_ACCEPT_TASK]: stopNavigateOnAcceptTask,
            layoutName: isDesktopLayoutFound[0].layoutName,
            layoutType,
            isRoleBasedLayout: isDesktopLayoutFound[0].isRoleBasedLayout,
            agentRole: STORE.agent.getUserRole
        });
        updateStoresWithLayoutDetails(isDesktopLayoutFound[0], isDesktopLayoutFound[1]);
        if (!cachedLayoutConfig) {
            logger.info("Desktop layout not found in cache, so adding dynamic routes from server desktop layout");
            agentXAppRouting && (yield agentXAppRouting.addOrUpdateDynamicRoutes());
        }
    }
    if (cachedLayoutConfig) {
        if (cachedLayoutConfig.userSelectedRole in cachedLayoutConfig.layout) {
            // Layout based on roles
            yield agentXAppRouting.addOrUpdateDynamicRoutes(cachedLayoutConfig.layout[cachedLayoutConfig.userSelectedRole]);
        }
        else {
            // Old version layout.
            const cacheLayout = cachedLayoutConfig.layout;
            yield agentXAppRouting.addOrUpdateDynamicRoutes(cacheLayout);
        }
    }
});
export { processCachedLayoutJson, processJSONLayout };
