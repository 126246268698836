var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RouteName } from "@/app/routing/routing";
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { agentContact } from "@/store/store-agent-contact";
import { SERVICE } from "@agentx/agentx-services";
import { action, computed, observable } from "mobx";
export class ModuleRouting {
    constructor() {
        this.routes = [];
        this._currentRouteName = null;
        this._currentRouteValues = JSON.stringify({
            params: {},
            query: {}
        });
        //  Mix  panel tracking for time spent on Analyzer reports.
        this.telemetryTrackingForNavigation = () => {
            // track all the navigated pages
            const navigationTime = SERVICE.telemetry.getValueFromCache(this._currentRouteName ? this._currentRouteName : "0");
            let timeSpentInThePage = {};
            if (Number(navigationTime) > 0) {
                timeSpentInThePage = {
                    [SERVICE.telemetry.TELEM_PROPS.TOTAL_TIME_SPENT]: Number(((new Date().getTime() - Number(navigationTime)) / 1000).toFixed(2))
                };
            }
            SERVICE.telemetry.track(SERVICE.telemetry.TELEM_EVENT.DESKTOP_VISITED_PAGE, Object.assign({ [SERVICE.telemetry.TELEM_PROPS.PAGE_NAME]: this._currentRouteName }, timeSpentInThePage));
            SERVICE.telemetry.removeValueFromCache(this._currentRouteName ? this._currentRouteName : "");
        };
    }
    /**
     * Computed
     */
    // App current routeName
    get currentRouteName() {
        return this._currentRouteName;
    }
    // App current routeValues
    get currentRouteValues() {
        return JSON.parse(this._currentRouteValues);
    }
    // Nav decorated routeName
    get forNavRouteName() {
        switch (this._currentRouteName) {
            case RouteName.TASKPANEL:
                return RouteName.TASK;
            case RouteName.QUEUE_DETAILS:
                return RouteName.QUEUES;
            default:
                return this._currentRouteName;
        }
    }
    /**
     * Actions
     */
    // Router -> Store sync
    sync(routeName, routeValues) {
        this._currentRouteName = routeName;
        if (routeValues) {
            this._currentRouteValues = JSON.stringify(routeValues);
        }
    }
    syncQueryParams(query) {
        this._currentRouteValues = JSON.stringify({ params: this.currentRouteValues.params, query });
    }
    // Common Navigate
    navigate(routeName, routeValues) {
        this.sync(routeName, routeValues);
    }
    // Home navigate
    navigateHome() {
        var _a;
        const homeRouteName = (_a = this.routes.find((route) => route.path === "/")) === null || _a === void 0 ? void 0 : _a.name;
        homeRouteName && homeRouteName !== RouteName.DEFAULT_HOME && this.navigate(homeRouteName);
    }
    // Interaction Navigate
    navigateInteractionTab() {
        // Todo: For now navigate to interactions tab, we can add the filters later
        this.navigateNav("interactions");
    }
    // Queues Navigate
    navigateQueue(queueId) {
        if (STORE.agentContact.checkBeforeNavigation && queueId !== STORE.agentContact.selectedQueueId) {
            const callback = this.navigateQueueOnConfirm.bind(this, queueId);
            this.showNavigationErrorModal(callback);
        }
        else {
            this.navigateQueueOnConfirm(queueId);
        }
    }
    navigateQueueOnConfirm(queueId) {
        this.navigate(RouteName.QUEUE_DETAILS, {
            params: Object.assign(Object.assign({}, STORE.routing.currentRouteValues.params), { queueId }),
            query: STORE.routing.currentRouteValues.query
        });
    }
    // Task Navigate
    navigateTask(taskId) {
        if (STORE.agentContact.checkBeforeNavigation && taskId !== STORE.agentContact.selectedTaskId) {
            const callback = this.navigateTaskOnConfirm.bind(this, taskId);
            this.showNavigationErrorModal(callback);
        }
        else {
            this.navigateTaskOnConfirm(taskId);
        }
    }
    navigateTaskOnConfirm(taskId) {
        STORE.agentContact.selectedTaskChange(taskId);
        this.navigate(RouteName.TASKPANEL, {
            params: Object.assign(Object.assign({}, STORE.routing.currentRouteValues.params), { taskId }),
            query: STORE.routing.currentRouteValues.query
        });
    }
    showNavigationErrorModal(callback) {
        STORE.session.errorModal.setErrorDetails({
            detail: {
                modalType: "dialog",
                header: t("app:warningMessage:header"),
                message: t("app:warningMessage:message"),
                buttons: [
                    {
                        type: "button",
                        label: t("app:warningMessage:cancelButton"),
                        color: "var(--md-background-color--gray-05)",
                        onClick: () => {
                            STORE.session.errorModal.updateModalStatus(false);
                        }
                    },
                    {
                        type: "button",
                        label: t("app:warningMessage:saveButton"),
                        onClick: () => {
                            STORE.session.errorModal.updateModalStatus(false);
                            STORE.agentContact.resetWarningOnNavigation();
                            callback();
                        }
                    }
                ]
            }
        });
    }
    // Nav Navigate
    navigateNav(navigateTo) {
        STORE.agentContact.openCADSection = false;
        if (STORE.agentContact.checkBeforeNavigation && STORE.agentContact.checkTelephonyTask()) {
            const callback = this.navigateNavOnConfirm.bind(this, navigateTo);
            this.showNavigationErrorModal(callback);
        }
        else {
            this.navigateNavOnConfirm(navigateTo);
        }
    }
    setRoutes(routes) {
        this.routes = [...routes];
    }
    getRoutes() {
        return this.routes;
    }
    navigateNavOnConfirm(navigateTo) {
        this.telemetryTrackingForNavigation();
        if (navigateTo === RouteName.TASK && agentContact.selectedTaskId) {
            this.navigateTask(agentContact.selectedTaskId);
        }
        else {
            this.navigate(navigateTo);
        }
    }
}
__decorate([
    observable
], ModuleRouting.prototype, "routes", void 0);
__decorate([
    observable
], ModuleRouting.prototype, "_currentRouteName", void 0);
__decorate([
    observable
], ModuleRouting.prototype, "_currentRouteValues", void 0);
__decorate([
    computed
], ModuleRouting.prototype, "currentRouteName", null);
__decorate([
    computed
], ModuleRouting.prototype, "currentRouteValues", null);
__decorate([
    computed
], ModuleRouting.prototype, "forNavRouteName", null);
__decorate([
    action
], ModuleRouting.prototype, "sync", null);
__decorate([
    action
], ModuleRouting.prototype, "syncQueryParams", null);
__decorate([
    action
], ModuleRouting.prototype, "navigate", null);
__decorate([
    action
], ModuleRouting.prototype, "navigateHome", null);
__decorate([
    action
], ModuleRouting.prototype, "navigateInteractionTab", null);
__decorate([
    action
], ModuleRouting.prototype, "navigateQueue", null);
__decorate([
    action
], ModuleRouting.prototype, "navigateTask", null);
__decorate([
    action
], ModuleRouting.prototype, "navigateNav", null);
__decorate([
    action
], ModuleRouting.prototype, "setRoutes", null);
__decorate([
    action
], ModuleRouting.prototype, "getRoutes", null);
export const routing = new ModuleRouting();
