var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { analyzerSessionHelper } from "@agentx/agentx-mfe-wc-based";
import { SERVICE } from "@agentx/agentx-services";
import { OAuth } from "@uuip/unified-ui-platform";
import { action, computed, observable } from "mobx";
import { handleIframeLogout, unRegisterWebexSDK } from "../app/utils/Utils";
const logger = createLogger("[store-auth]");
export class ModuleAuth {
    constructor() {
        this.authService = new OAuth.Service({
            clientId: process.env.AUTH_CLIENT_ID,
            clientSecret: process.env.AUTH_CLIENT_SECRET,
            scopes: process.env.AUTH_SCOPE,
            idbrokerUrl: process.env.ID_BROKER_URL,
            identityUrl: process.env.IDENTITY_URL,
            flsUrl: process.env.FLS_URL,
            useSelfContained: "true"
        });
        this.accessToken = "";
        this.refreshToken = "";
        this.iframeLogoutRequested = false;
        this.isWebexLogoutRequested = false;
        // Microsoft Authentication Services
        this.microsoftAuthServiceIsEnabled = false;
        this.microsoftAuthServiceIsAuthenticated = false;
        this.requestCiLogout = (token) => {
            //websocket disconnect done explicitly since AQM logout does not disconnect websocket connection
            SERVICE.aqm.closeSocket(false, "SelfLogout");
            if (window.self !== window.top) {
                this.setIFrameLogoutRequested(true);
                handleIframeLogout();
            }
            else {
                this.authService.logout(token);
            }
        };
    }
    get getMicrosoftAuthConfig() {
        return {
            isEnabled: this.microsoftAuthServiceIsEnabled,
            isAuthenticated: this.microsoftAuthServiceIsAuthenticated // This flag is used to show the error on profile setting.
        };
    }
    setIFrameLogoutRequested(value) {
        this.iframeLogoutRequested = value;
    }
    setIsWebexLogoutRequested(value) {
        this.isWebexLogoutRequested = value;
    }
    tokenSet(token) {
        this.accessToken = token;
    }
    updateCiDetails(identityUrl, idbrokerUrl) {
        STORE.auth.authService.config.identityUrl = identityUrl;
        STORE.auth.authService.config.idbrokerUrl = idbrokerUrl;
    }
    logout(token) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const promiseLogout = analyzerSessionHelper.analyzerLogout();
            this.setIsWebexLogoutRequested(true);
            if (SERVICE.webex) {
                logger.info("[Webex] : logout initiated to delete the device registered with webex-js-sdk!");
                yield unRegisterWebexSDK();
            }
            // dispatch event when CI logout is triggered from desktop so that js-sdk can listen to this event when required
            window.dispatchEvent(new CustomEvent("desktop-ci-logout", {
                bubbles: true,
                composed: true
            }));
            let timeoutId;
            // Trigger Microsoft Logout, If user is Authenticated.
            if (STORE.auth.getMicrosoftAuthConfig.isAuthenticated) {
                logger.info("[Microsoft Auth]: initiating msLogout");
                (_a = SERVICE.microsoft) === null || _a === void 0 ? void 0 : _a.msLogout().then(() => {
                    logger.info("[Microsoft Auth]: Microsoft User Signout form Desktop is success", timeoutId);
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                        this.requestCiLogout(token);
                    }
                });
            }
            promiseLogout
                .finally(() => {
                if (!STORE.app.webexEnabled && !STORE.auth.getMicrosoftAuthConfig.isAuthenticated) {
                    this.requestCiLogout(token);
                }
                else {
                    const timeoutDuration = STORE.auth.getMicrosoftAuthConfig.isAuthenticated ? 10000 : 5000;
                    timeoutId = setTimeout(() => {
                        this.requestCiLogout(token);
                    }, timeoutDuration);
                }
            })
                .catch((error) => {
                logger.error(`event=AnalyzerLogout | Failed to logout from Analyzer - ${error}`, error);
            });
        });
    }
    refreshTokenSet(token) {
        this.refreshToken = token;
    }
    // Microsoft Authentication Services
    updateMicrosoftAuthServiceEnabled(status) {
        this.microsoftAuthServiceIsEnabled = status;
    }
    updateMicrosoftAuthServiceAuthenticated(status) {
        this.microsoftAuthServiceIsAuthenticated = status;
    }
}
__decorate([
    observable
], ModuleAuth.prototype, "authService", void 0);
__decorate([
    observable
], ModuleAuth.prototype, "accessToken", void 0);
__decorate([
    observable
], ModuleAuth.prototype, "refreshToken", void 0);
__decorate([
    observable
], ModuleAuth.prototype, "iframeLogoutRequested", void 0);
__decorate([
    observable
], ModuleAuth.prototype, "isWebexLogoutRequested", void 0);
__decorate([
    observable
], ModuleAuth.prototype, "microsoftAuthServiceIsEnabled", void 0);
__decorate([
    observable
], ModuleAuth.prototype, "microsoftAuthServiceIsAuthenticated", void 0);
__decorate([
    computed
], ModuleAuth.prototype, "getMicrosoftAuthConfig", null);
__decorate([
    action
], ModuleAuth.prototype, "setIFrameLogoutRequested", null);
__decorate([
    action
], ModuleAuth.prototype, "setIsWebexLogoutRequested", null);
__decorate([
    action
], ModuleAuth.prototype, "tokenSet", null);
__decorate([
    action
], ModuleAuth.prototype, "updateCiDetails", null);
__decorate([
    action
], ModuleAuth.prototype, "logout", null);
__decorate([
    action
], ModuleAuth.prototype, "refreshTokenSet", null);
__decorate([
    action
], ModuleAuth.prototype, "updateMicrosoftAuthServiceEnabled", null);
__decorate([
    action
], ModuleAuth.prototype, "updateMicrosoftAuthServiceAuthenticated", null);
export const auth = new ModuleAuth();
