import { Router } from "@uuip/unified-ui-platform";
import { STORE } from "@/store";
import { BaseView } from "../_base-view/BaseView";
import QueueStatsDetailsBlock from "./blocks/QueueStatsDetailsBlock";
import RouteNotFoundBlock from "../_not-found-view/blocks/RouteNotFoundBlock";
import { css } from "lit-element";
import style from "./QueueStatsDetailsView.scss";
const isValidQueueId = (queueId) => {
    const queueExists = STORE.agent.managedQueues.some((queue) => queue.queueId === queueId);
    if (queueExists) {
        STORE.agentContact.selectedQueueStatsQueueChange(queueId);
    }
    return queueExists;
};
export const QueueStatsDetailsView = (queueId) => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: isValidQueueId(queueId)
            ? { queue_detail: QueueStatsDetailsBlock() }
            : {
                route_not_found: RouteNotFoundBlock("/", "route-not-found") // redirect to default route
            },
        styles: [
            style,
            isValidQueueId(queueId)
                ? css `
            :host {
              grid-template-areas:
                "header header header header"
                "nav banner banner banner"
                "nav tasks common-control common-control"
                "nav tasks queue-detail queue-detail";
            }
          `
                : css `
            :host {
              grid-template-areas:
                "header header header header"
                "nav banner banner banner"
                "nav tasks common-control common-control"
                "nav tasks route_not_found route_not_found";
            }
          `
        ]
    });
};
