var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable sonarjs/no-duplicate-string */
/*eslint @typescript-eslint/no-unused-vars: "off"*/
import { DynamicView } from "@/app/routing/views/_dynamic-view/DynamicView";
import { NotFoundView } from "@/app/routing/views/_not-found-view/NotFoundView";
import { ApsView } from "@/app/routing/views/aps-view/ApsView";
import { TaskSelectedView } from "@/app/routing/views/task-selected-view/TaskSelectedView";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { DEFAULT_NAVIGATION_ITEMS } from "@/store/init/default-configs";
import { Router } from "@uuip/unified-ui-platform";
import { autorun } from "mobx";
import { UserRoles } from "../utils/DesktopLayoutUtils";
import { isInteractionsPageEnabled, updatePageTitle } from "../utils/Utils";
import DefaultLandingView from "./views/default-landing-view/DefaultLandingView";
import { HomeView } from "./views/home-view/HomeView";
import InteractionsView from "./views/interactions-view/interactions-view";
import { QueueStatsDetailsView } from "./views/queue-stats-details-view/QueueStatsDetailsView";
import QueueStatsView from "./views/queue-stats-view/QueueStatsView";
// Routes names for better usage
export var RouteName;
(function (RouteName) {
    RouteName["TASK"] = "task";
    RouteName["TASKPANEL"] = "task-panel";
    RouteName["APS"] = "aps";
    RouteName["QUEUES"] = "queues";
    RouteName["QUEUE_DETAILS"] = "queue-details";
    RouteName["METRICS"] = "metrics";
    RouteName["Interactions"] = "interactions";
    RouteName["DEFAULT_HOME"] = "defaulthome";
})(RouteName || (RouteName = {}));
// Route paths
export const RoutePath = {
    [RouteName.TASK]: "/",
    [RouteName.TASKPANEL]: "/task/:taskId",
    [RouteName.APS]: "/aps",
    [RouteName.QUEUES]: "/queues",
    [RouteName.QUEUE_DETAILS]: "/queue/:queueId",
    [RouteName.Interactions]: "/interactions",
    [RouteName.METRICS]: "/metrics"
};
const logger = createLogger("[AgentXAppRouting]");
// Class-adapter that bind routes/views change to element view
class AgentXAppRouting {
    constructor(router, agentxStore) {
        this.mobxSyncReactionDispose = null;
        this.syncFromStoreToRouter = () => __awaiter(this, void 0, void 0, function* () {
            if (this.STORE.routing.currentRouteName) {
                yield this.navigate(this.STORE.routing.currentRouteName, this.STORE.routing.currentRouteValues);
            }
        });
        this.syncFromRouterToStore = (routeName, payload) => {
            const { routeValues, middlewareKey } = payload;
            // DO not sync "after" callback - it provides cleanup flow
            if (middlewareKey !== "after") {
                this.STORE.routing.sync(routeName, routeValues);
            }
        };
        this.viewMapFactory = Router.RouterView.Noop;
        this.updateViewNotFound = () => __awaiter(this, void 0, void 0, function* () {
            const notFoundHref = window.location.href;
            yield this.updateView(() => NotFoundView(notFoundHref));
        });
        this.router = router;
        this.STORE = agentxStore;
    }
    notifyRoutingUpdate() {
        return __awaiter(this, void 0, void 0, function* () {
            this.config && this.config.onRoutingUpdate && (yield this.config.onRoutingUpdate());
        });
    }
    findRemovedItems(cachedNavItems) {
        var _a, _b;
        let removedItems = [];
        if (this.STORE.dynamic.navigation) {
            const updatedNavItems = this.STORE.dynamic.navigation;
            removedItems = cachedNavItems.filter(({ nav: fromCache }) => !updatedNavItems.some(({ nav: fromServer }) => fromCache.navigateTo === fromServer.navigateTo));
            if (((_b = (_a = this.STORE) === null || _a === void 0 ? void 0 : _a.app) === null || _b === void 0 ? void 0 : _b.wxmEnabled) === false) {
                const wxmNavItem = cachedNavItems === null || cachedNavItems === void 0 ? void 0 : cachedNavItems.find((p) => p.page.id === "wxm-metrics");
                if (wxmNavItem) {
                    removedItems.push(wxmNavItem);
                }
            }
        }
        return removedItems;
    }
    findNewlyAddedItems(cachedNavItems) {
        let updatedNavItems = [];
        if (this.STORE.dynamic.navigation) {
            updatedNavItems = this.STORE.dynamic.navigation.filter(({ nav: fromServer }) => !cachedNavItems.some(({ nav: fromCache }) => fromCache.label === fromServer.label));
        }
        return updatedNavItems;
    }
    updateView(viewMapFactory) {
        return __awaiter(this, void 0, void 0, function* () {
            this.viewMapFactory = viewMapFactory;
            yield this.notifyRoutingUpdate();
        });
    }
    get view() {
        return this.viewMapFactory();
    }
    start(config) {
        // For Sync view
        this.config = config;
        // Setup sync update routing from store to service
        this.mobxSyncReactionDispose = autorun(this.syncFromStoreToRouter);
        const isDefaultLandingpageEnabled = STORE.agent.role !== UserRoles.AGENT;
        this.router.start({
            onUpdate: (payload) => __awaiter(this, void 0, void 0, function* () {
                const routeName = this.router.getRouteNameByPath(payload.routePath);
                this.syncFromRouterToStore(routeName, payload);
                yield this.notifyRoutingUpdate();
            }),
            routes: !isDefaultLandingpageEnabled
                ? [
                    {
                        name: RouteName.TASK,
                        path: RoutePath[RouteName.TASK],
                        before: () => __awaiter(this, void 0, void 0, function* () {
                            /** May show some preloader view */
                        }),
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => HomeView()); })
                    },
                    {
                        name: RouteName.TASKPANEL,
                        path: RoutePath[RouteName.TASKPANEL],
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () {
                            const homeHref = this.router.buildNamedRoutePathUrl(RouteName.TASK);
                            yield this.updateView(() => TaskSelectedView(homeHref));
                        })
                    },
                    {
                        name: RouteName.APS,
                        path: RoutePath[RouteName.APS],
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => ApsView()); })
                    }
                ]
                : [
                    {
                        name: RouteName.DEFAULT_HOME,
                        path: `/`,
                        before: () => __awaiter(this, void 0, void 0, function* () {
                            /** May show some preloader view */
                        }),
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => DefaultLandingView()); })
                    },
                    {
                        name: RouteName.APS,
                        path: RoutePath[RouteName.APS],
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => ApsView()); })
                    },
                    {
                        name: RouteName.TASK,
                        path: `/${RouteName.TASK}`,
                        before: () => __awaiter(this, void 0, void 0, function* () {
                            /** May show some preloader view */
                        }),
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => HomeView()); })
                    },
                    {
                        name: RouteName.TASKPANEL,
                        path: RoutePath[RouteName.TASKPANEL],
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () {
                            const taskPageHref = this.router.buildRoutePathUrl(RoutePath[RouteName.TASK]);
                            yield this.updateView(() => TaskSelectedView(taskPageHref));
                        })
                    },
                    {
                        name: RouteName.Interactions,
                        path: RoutePath[RouteName.Interactions],
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () {
                            isInteractionsPageEnabled()
                                ? yield this.updateView(() => InteractionsView())
                                : yield this.updateViewNotFound();
                        })
                    },
                    {
                        name: RouteName.QUEUES,
                        path: RoutePath[RouteName.QUEUES],
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () {
                            STORE.app.featureFlags.isQueueStatsTabEnabled
                                ? yield this.updateView(() => QueueStatsView())
                                : this.updateViewNotFound();
                        })
                    },
                    {
                        name: RouteName.QUEUE_DETAILS,
                        path: RoutePath[RouteName.QUEUE_DETAILS],
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () {
                            STORE.app.featureFlags.isQueueStatsTabEnabled
                                ? yield this.updateView(() => QueueStatsDetailsView(STORE.routing.currentRouteValues.params.queueId))
                                : this.updateViewNotFound();
                        })
                    }
                ],
            notFound: {
                resolver: () => __awaiter(this, void 0, void 0, function* () {
                    this.updateViewNotFound();
                })
            }
        });
        this.router.getRoutes && STORE.routing.setRoutes(this.router.getRoutes());
    }
    stop() {
        this.mobxSyncReactionDispose && this.mobxSyncReactionDispose();
        this.router.stop();
    }
    navigate(routeName, routeValues) {
        return __awaiter(this, void 0, void 0, function* () {
            logger.info("Navigating to", routeName, routeValues);
            try {
                updatePageTitle(routeName);
                STORE.dynamic.updateSelectedRoute(routeName);
            }
            catch (e) {
                logger.error("Error while updating title", e.message);
            }
            this.router.getRoutes().find((route) => route.name === routeName) &&
                (yield this.router.navigateNamedRoute(routeName, routeValues));
        });
    }
    isTaskrouteIsNotDefaultPath() {
        const currentRoutes = this.router.getRoutes();
        const currentTaskRoute = currentRoutes.find((it) => it.path !== "/" && it.name === RouteName.TASK);
        return currentTaskRoute || false;
    }
    checkRouteNameExists(name) {
        var _a, _b;
        return (_b = (_a = this.router.getRoutes()) === null || _a === void 0 ? void 0 : _a.find((router) => router.name === name)) !== null && _b !== void 0 ? _b : false;
    }
    isCurrentRouteExist() {
        if (STORE.routing.currentRouteName) {
            const response = this.checkRouteNameExists(STORE.routing.currentRouteName);
            return !response;
        }
        return false;
    }
    isTaskRouteExists(previousRoutes) {
        var _a;
        if (STORE.routing.currentRouteName === RouteName.TASK) {
            // Check the current task route path and previous task name routh path are same or not.
            const isTaskExist = previousRoutes === null || previousRoutes === void 0 ? void 0 : previousRoutes.find((it) => it.name === RouteName.TASK);
            if (!isTaskExist) {
                return true;
            }
            const response = (_a = this.router
                .getRoutes()) === null || _a === void 0 ? void 0 : _a.find((route) => route.name === RouteName.TASK && route.path === isTaskExist.path);
            return !response;
        }
        return false;
    }
    addOrUpdateDynamicRoutes(cachedLayoutConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.STORE.dynamic.navigation) {
                return;
            }
            yield this.syncRoutesWithServerUpdates(cachedLayoutConfig);
        });
    }
    syncRoutesWithServerUpdates(cachedLayoutConfig) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const updateRoutes = [];
            const newRoutes = [];
            const removeRoutes = [];
            const newNavs = [];
            const removeNavs = [];
            const homePage = STORE.dynamic.homepage || undefined;
            const previousRoutes = this.router.getRoutes ? [...this.router.getRoutes()] : [];
            const defaultLandingPageRouteName = (_b = (_a = STORE.dynamic.navigation) === null || _a === void 0 ? void 0 : _a.find((nav) => nav.nav.isDefaultLandingPage)) === null || _b === void 0 ? void 0 : _b.nav.navigateTo;
            if (STORE.agent.role !== UserRoles.AGENT) {
                yield this.handleAgentRole(updateRoutes, newRoutes, homePage, cachedLayoutConfig);
            }
            const dynamicNavItems = {
                "wxm-metrics": STORE.app.wxmEnabled,
                "qmw-sup": STORE.agent.getRecordingManagementEnabled
            };
            if (STORE.dynamic.navigation) {
                this.handleDynamicNavigation(updateRoutes, newRoutes, newNavs, dynamicNavItems);
            }
            if ((_c = cachedLayoutConfig === null || cachedLayoutConfig === void 0 ? void 0 : cachedLayoutConfig.area) === null || _c === void 0 ? void 0 : _c.navigation) {
                this.handleRemoveRoutes(cachedLayoutConfig, updateRoutes, newRoutes, removeRoutes, removeNavs);
            }
            yield this.applyNavChanges(newNavs);
            if (updateRoutes.length > 0) {
                logger.info(`Updating exiting dynamic routes from server desktop layout ${JSON.stringify(updateRoutes)}`);
                yield this.router.updateRoutes(updateRoutes);
            }
            if (newRoutes.length > 0) {
                logger.info(`Adding new dynamic routes the routes from server desktop layout ${JSON.stringify(newRoutes)}`);
                yield this.router.addRoutes(newRoutes);
            }
            const isRemovedRouteCurrentRoute = removeNavs.find((p) => p.nav.navigateTo === STORE.routing.currentRouteName);
            if (defaultLandingPageRouteName) {
                STORE.routing.navigate(defaultLandingPageRouteName);
            }
            else if (isRemovedRouteCurrentRoute || this.isCurrentRouteExist() || this.isTaskRouteExists(previousRoutes)) {
                if (homePage) {
                    STORE.routing.navigate(homePage.nav.navigateTo);
                }
                else {
                    STORE.routing.navigate(RouteName.TASK);
                }
            }
            if (removeRoutes.length > 0) {
                logger.info(`Navigation removed from desktop layout", ${JSON.stringify(removeRoutes)}, Removing from routes`);
                removeNavs === null || removeNavs === void 0 ? void 0 : removeNavs.forEach((p) => STORE.layout.removeDynamicNavIcon(p.nav));
                yield this.router.removeRoutes(removeRoutes);
            }
            this.router.getRoutes && STORE.routing.setRoutes(this.router.getRoutes());
            this.notifyRoutingUpdate();
        });
    }
    handleAgentRole(updateRoutes, newRoutes, homePage, cachedLayoutConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            if (homePage) {
                const newHomeRoute = {
                    name: homePage.nav.navigateTo,
                    path: `/`,
                    resolver: () => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => DynamicView(homePage.page)); })
                };
                updateRoutes.push([{ path: "/" }, newHomeRoute]);
                if (!this.isTaskrouteIsNotDefaultPath()) {
                    const taskRoute = {
                        name: RouteName.TASK,
                        path: `/${RouteName.TASK}`,
                        resolver: (_payload) => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => HomeView()); })
                    };
                    newRoutes.push(taskRoute);
                }
                STORE.layout.addDynamicHomeNav(homePage.nav);
            }
            else {
                yield this.handleNoHomePage(updateRoutes, cachedLayoutConfig);
            }
        });
    }
    applyNavChanges(newNavs) {
        return __awaiter(this, void 0, void 0, function* () {
            if (newNavs.length > 0) {
                newNavs.forEach((p) => {
                    STORE.layout.addDynamicNavIcon(p.nav);
                });
            }
        });
    }
    handleNoHomePage(updateRoutes, cachedLayoutConfig) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let previousHomeRouteName = RouteName.DEFAULT_HOME;
            if ((_b = (_a = cachedLayoutConfig === null || cachedLayoutConfig === void 0 ? void 0 : cachedLayoutConfig.area) === null || _a === void 0 ? void 0 : _a.homepage) === null || _b === void 0 ? void 0 : _b.nav) {
                const cacheRouteName = cachedLayoutConfig.area.homepage.nav.navigateTo;
                const isRouteExists = this.checkRouteNameExists(cacheRouteName);
                if (cacheRouteName && isRouteExists) {
                    previousHomeRouteName = cacheRouteName;
                }
            }
            const newTaskRoute = {
                name: RouteName.TASK,
                path: `/`,
                resolver: () => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => HomeView()); })
            };
            updateRoutes.push([{ name: previousHomeRouteName }, newTaskRoute]);
            if (STORE.routing.currentRouteName === RouteName.TASK) {
                STORE.routing.navigate(previousHomeRouteName);
            }
            yield this.router.removeRoutes([{ path: `/${RouteName.TASK}` }]);
            STORE.layout.removeDynamicNavigationTo(previousHomeRouteName);
            STORE.layout.addDefaultNavIcon(DEFAULT_NAVIGATION_ITEMS[0]);
        });
    }
    handleDynamicNavigation(updateRoutes, newRoutes, newNavs, dynamicNavItems) {
        const navigation = STORE.dynamic.navigation;
        if (!navigation) {
            return; // Exit early if navigation is null or undefined
        }
        navigation.forEach((p) => __awaiter(this, void 0, void 0, function* () {
            let navToBeAdded = true;
            if (dynamicNavItems[p.page.id] !== undefined) {
                navToBeAdded = dynamicNavItems[p.page.id];
            }
            if (navToBeAdded) {
                const route = {
                    name: p.nav.navigateTo,
                    path: `/${p.nav.navigateTo}`,
                    resolver: () => __awaiter(this, void 0, void 0, function* () { return this.updateView(() => DynamicView(p.page)); })
                };
                if (this.router.getRoutePathByName(p.nav.navigateTo)) {
                    updateRoutes.push([{ name: p.nav.navigateTo }, route]);
                    newNavs.push(p);
                }
                else {
                    newRoutes.push(route);
                    newNavs.push(p);
                }
            }
        }));
    }
    handleRemoveRoutes(cachedLayoutConfig, updateRoutes, newRoutes, removeRoutes, removeNavs) {
        var _a;
        const removingNavItems = this.findRemovedItems((_a = cachedLayoutConfig.area.navigation) !== null && _a !== void 0 ? _a : []).filter((p) => {
            const u = updateRoutes.find(([routeIdentity, route]) => route.name === p.nav.navigateTo);
            const n = newRoutes.find((route) => route.name === p.nav.navigateTo);
            return !u && !n;
        });
        removingNavItems.forEach((p) => {
            removeRoutes.push({
                name: p.nav.navigateTo
            });
            removeNavs.push(p);
        });
    }
}
export const createAgentXAppRouting = (agentxStore) => new AgentXAppRouting(Router.router, agentxStore);
