import { RouteName } from "@/app/routing/routing";
import { handlePresenceCallState } from "@/app/utils/StateSyncManager/UtilsSyncState";
import { UTILS_DYNAMIC } from "@/app/utils/UtilsDynamic";
import { t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { MediaType } from "@/store/store-agent-contact";
import { SERVICE } from "@agentx/agentx-services";
import { Router } from "@uuip/unified-ui-platform";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { html, nothing } from "lit-html";
import { toJS } from "mobx";
import { BARGE_IN_MONITORING_STATE, SUPERVISOR_EVENT, WEBEX_MINIMIZE_MODAL, WEBEX_SHOW_MODAL } from "../../components/constants";
import { bargeInStarted, contactCallbackRetryModal, getAgentCallMonitoringStateOnBargeIn, getErrorMsgForReasonCode, handleAutoAnswer, inviteFailedToIdle, isSecondaryEpDnAgent, midcallMonitoringEnded, midcallMonitoringFailed, midcallMonitoringOffered, midcallMonitoringStarted, ronaToOtherState, showContactCleanUpModal, showErrorModal, triggerBargeInNotification, triggerLeaveInteractionNotification, updatePageTitle } from "../../utils/Utils";
const logger = createLogger("[UtilityBlock]");
const RACE_ERROR_REASON_CODE = 53;
const UtilityBlock = () => {
    var _a, _b;
    return new Router.RouterView.Block({
        template: html `
      <!-- EMPTY -->

      <div class="headless">
        <!-- EMPTY -->
        <agentx-react-utility
          .configs="${STORE.agentContact.chatConferenceData}"
          .imiconfigs="${STORE.app.imiConfigs}"
          .mediaViewOption="${STORE.session.chat.mediaViewOption}"
          .deviceType="${STORE.agent.deviceType}"
          @ax-media-view-init-state="${(e) => {
            STORE.session.chat.setMediaViewInitState(e.detail);
        }}"
          @agent-contact-reserved="${(e) => {
            STORE.agentContact.updateTask(e.detail);
        }}"
          @agent-offer-contact="${(e) => {
            STORE.agentContact.updateTask(e.detail);
            handleAutoAnswer(e.detail);
        }}"
          @agent-offer-consult="${(e) => {
            STORE.agentContact.updateTask(e.detail);
            handleAutoAnswer(e.detail);
        }}"
          @agent-contact-assigned="${(e) => {
            // need to clear the previous interaction id received for add campaign (When agent leg is established)
            if (STORE.app.featureFlags.isProgressiveCampaign1NEnabled && e.detail.reservationInteractionId) {
                STORE.agentContact.deleteTask(e.detail.reservationInteractionId, true);
            }
            STORE.agentContact.updateTask(e.detail);
            STORE.agentContact.checkBeforeSelectingTask(e.detail.interactionId);
            STORE.agentContact.updateCallMonitoringObjAndTriggerNotification(e.detail);
            if (e.detail.interaction.mediaType === MediaType.Telephony) {
                STORE.session.errorModal.updateModalStatus(false);
                handlePresenceCallState(e.detail);
            }
        }}"
          @agent-contact-assigned-failed="${(e) => {
            STORE.agentContact.handleAgentContactAssignFailure(e.detail);
        }}"
          @agent-contact="${(e) => {
            var _a;
            STORE.agentContact.updateAgentCallMonitoringStateDetails(e.detail);
            STORE.agentContact.updateTask(e.detail);
            if (STORE.routing.currentRouteName === RouteName.TASK &&
                STORE.agentContact.interactionIds.length === STORE.agentContact.taskMap.size) {
                STORE.agentContact.selectTaskOnHomeRoute();
            }
            if (STORE.routing.currentRouteValues.params.taskId === e.detail.interactionId) {
                STORE.agentContact.selectedTaskChange(e.detail.interactionId);
                STORE.routing.navigateTask(e.detail.interactionId);
            }
            STORE.session.interactionControl.setAutoWrapupState(e.detail, STORE.agent.agentId, (_a = SERVICE.conf.profile) === null || _a === void 0 ? void 0 : _a.wrapUpData);
            STORE.session.interactionControl.checkForWrapUp(e.detail, STORE.agent.agentId);
        }}"
          @contact-held=${(e) => {
            if ((e.detail.interactionId !== STORE.agentContact.selectedTaskId &&
                !STORE.agentContact.isPrimary(e.detail, STORE.agent.agentId) &&
                STORE.routing.currentRouteName === RouteName.TASKPANEL) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType !== MediaType.Telephony) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType === MediaType.Telephony &&
                    STORE.agentContact.selectedTaskId !== "" &&
                    e.detail.interactionId !== STORE.agentContact.selectedTaskId &&
                    !STORE.agentContact.isPrimary(e.detail, STORE.agent.agentId))) {
                const notificationData = {
                    type: Notifications.ItemMeta.Type.Info,
                    mode: Notifications.ItemMeta.Mode.AutoDismiss,
                    title: t("app:notifications.contactHeldNotifTitle"),
                    data: t("app:notifications.contactHeldNotifData")
                };
                STORE.generalNotifications.fireNotificationEvent(notificationData);
            }
            STORE.agentContact.updateHeldUnheldProps(e.detail);
        }}
          @contact-unheld=${(e) => {
            STORE.agentContact.updateHeldUnheldProps(e.detail);
        }}
          @agent-wrapup=${(e) => {
            if (STORE.common.isInteractionModelOpen && STORE.common.interactionModelOpenId === e.detail.interactionId) {
                STORE.common.deleteInteractionModalState();
            }
            STORE.agentContact.updateTask(e.detail);
            if (e.detail.interaction.contactDirection.type === "OUTBOUND") {
                STORE.agentContact.checkBeforeSelectingTask(e.detail.interactionId);
            }
            STORE.session.email.deleteEmailFromCache(e.detail.interactionId);
            if (STORE.agent.wrapUpData.wrapUpProps.autoWrapup) {
                STORE.session.interactionControl.addAutoWrapUpTimers(e.detail.interactionId, STORE.app.multipartyOrPersistsFF
                    ? e.detail.interaction.participants[STORE.agent.agentId]["lastUpdated"]
                    : e.detail.interaction.participants[STORE.agent.agentId]["wrapUpTimestamp"], toJS(STORE.agent.wrapUpData));
                STORE.session.interactionControl.addInteraction(e.detail.interactionId);
            }
            if (e.detail.interaction.mediaType === MediaType.Chat ||
                e.detail.interaction.mediaType === MediaType.Social) {
                STORE.session.chat.deleteUnreadMessages(e.detail.interactionId);
            }
        }}
          @agent-contact-wrappedup=${(e) => {
            if (STORE.common.isInteractionModelOpen && STORE.common.interactionModelOpenId === e.detail.interactionId) {
                STORE.common.deleteInteractionModalState();
            }
            STORE.agentContact.resetWarningOnNavigation();
            // remove persisted state of aux-panel tabs on interaction closed
            STORE.session.interactionControl.deleteAutoWrapTimer(e.detail.interactionId);
            sessionStorage.removeItem(e.detail.interactionId);
            if ((e.detail.interactionId !== STORE.agentContact.selectedTaskId &&
                STORE.agent.wrapUpData.wrapUpProps.autoWrapup &&
                STORE.routing.currentRouteName === RouteName.TASKPANEL) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType !== MediaType.Telephony) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType === MediaType.Telephony &&
                    STORE.agentContact.selectedTaskId !== "" &&
                    e.detail.interactionId !== STORE.agentContact.selectedTaskId)) {
                const notificationData = {
                    type: Notifications.ItemMeta.Type.Info,
                    mode: Notifications.ItemMeta.Mode.AutoDismiss,
                    title: t("app:notifications.autoWrapupTitle"),
                    data: t("app:notifications.autoWrapupData")
                };
                STORE.generalNotifications.fireNotificationEvent(notificationData);
            }
            STORE.agentContact.deleteAndSelectNextTask(e.detail.interactionId);
        }}
          @agent-contact-ended=${(e) => {
            if (STORE.common.isInteractionModelOpen && STORE.common.interactionModelOpenId === e.detail.interactionId) {
                STORE.common.deleteInteractionModalState();
            }
            if (e.detail.interaction.state === "new" ||
                (e.detail.interaction.state === "wrapUp" && // check if current state is wrapUp
                    e.detail.interaction.contactDirection.type === "OUTBOUND" &&
                    STORE.agentContact.isTaskRinging(e.detail.interactionId)) || // check if task previous state is ringing
                isSecondaryEpDnAgent(e.detail.interaction)) {
                // delete and remove task from desktop for contactEnd for agent2 side
                STORE.agentContact.deleteAndSelectNextTask(e.detail.interactionId);
            }
        }}
          @agent-contact-rona=${(e) => {
            STORE.agentContact.deleteTask(e.detail.interactionId);
            STORE.agent.updateStateToRona();
            ronaToOtherState(e.detail.interaction, e.detail.reasonCode);
        }}
          @agent-invite-failed=${(e) => {
            STORE.agentContact.deleteTask(e.detail.interactionId);
            if (e.detail.reasonCode === 1030 || e.detail.reasonCode === "1030") {
                // a usecase of customer left to treat this as outdial failed.
                STORE.agentContact.handleOutBoundFailed(e.detail);
            }
            else {
                STORE.agent.updateStateToRona();
                inviteFailedToIdle(e.detail.interaction, e.detail.trackingId);
            }
        }}
          @call-recording-started=${(e) => {
            STORE.agentContact.updateRecordingProps(e.detail);
        }}
          @agent-consult-created=${(e) => {
            STORE.agentContact.updateTask(e.detail);
        }}
          @agent-consulting=${(e) => {
            if ((e.detail.interaction.mediaType === MediaType.Chat ||
                e.detail.interaction.mediaType === MediaType.Social) &&
                STORE.agent.agentId === e.detail.interaction.owner) {
                const data = {
                    interactionId: e.detail.interactionId,
                    destAgentId: e.detail.destAgentId,
                    initiateConferenceChat: true,
                    agentId: STORE.agent.agentId
                };
                STORE.agentContact.updatechatConferenceData(data);
            }
            if ((e.detail.interactionId !== STORE.agentContact.selectedTaskId &&
                e.detail.interaction.mediaType === MediaType.Telephony &&
                STORE.agentContact.isPrimary(e.detail, STORE.agent.agentId) &&
                STORE.routing.currentRouteName === RouteName.TASKPANEL) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType !== MediaType.Telephony) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType === MediaType.Telephony &&
                    STORE.agentContact.selectedTaskId !== "" &&
                    e.detail.interactionId !== STORE.agentContact.selectedTaskId)) {
                const notificationData = {
                    type: Notifications.ItemMeta.Type.Info,
                    mode: Notifications.ItemMeta.Mode.AutoDismiss,
                    title: t("app:notifications.consultingNotifTitle"),
                    data: t("app:notifications.consultingNotifData")
                };
                STORE.generalNotifications.fireNotificationEvent(notificationData);
            }
            STORE.agentContact.handleConsulting(e.detail, STORE.agent.agentId);
            if (STORE.agent.agentId !== e.detail.interaction.owner) {
                handlePresenceCallState(e.detail);
            }
        }}
          @agent-consult-conferenced=${(e) => {
            var _a;
            STORE.agentContact.resetInteractionControlDimensions();
            if ((e.detail.interactionId !== STORE.agentContact.selectedTaskId &&
                STORE.agentContact.isPrimary(e.detail, STORE.agent.agentId) &&
                STORE.routing.currentRouteName === RouteName.TASKPANEL) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType !== MediaType.Telephony) ||
                (STORE.routing.currentRouteName === RouteName.APS &&
                    e.detail.interaction.mediaType === MediaType.Telephony &&
                    STORE.agentContact.selectedTaskId !== "" &&
                    e.detail.interactionId !== STORE.agentContact.selectedTaskId)) {
                const notificationData = {
                    type: Notifications.ItemMeta.Type.Info,
                    mode: Notifications.ItemMeta.Mode.AutoDismiss,
                    title: t("app:notifications.conferenceNotifTitle"),
                    data: t("app:notifications.conferenceNotifData")
                };
                STORE.generalNotifications.fireNotificationEvent(notificationData);
            }
            STORE.agentContact.updateTask(e.detail);
            if (e.detail.interaction.mediaType === MediaType.Chat ||
                e.detail.interaction.mediaType === MediaType.Social) {
                STORE.agentContact.selectAcceptedTask(e.detail, STORE.agent.agentId);
            }
            try {
                updatePageTitle(STORE.routing.currentRouteName);
            }
            catch (ex) {
                logger.error("[UtilityBlock] Error while updating title for conference", ex.message);
            }
            if (!((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.isOwner)) {
                STORE.agentContact.updateCallMonitoringObjAndTriggerNotification(e.detail);
            }
        }}
          @agent-contact-updated=${(e) => {
            STORE.agentContact.updateCadVariable(e.detail);
        }}
          @agent-consult-ended=${(e) => {
            STORE.agentContact.handleConsultUpdate(e.detail, STORE.agent.agentId);
        }}
          @agent-consult-conference-ended=${(e) => {
            STORE.agentContact.handleConsultUpdate(e.detail, STORE.agent.agentId);
        }}
          @contact-owner-changed=${(e) => {
            STORE.agentContact.handleContactOwnerChanged(e.detail, STORE.agent.agentId);
        }}
          @agent-conference-participantLeft=${(e) => {
            STORE.agentContact.handleConfParticipantLeft(e.detail, STORE.agent.agentId);
        }}
          @agent-consult-conferencing=${(e) => {
            STORE.agentContact.updateTask(e.detail);
        }}
          @agent-consult-failed=${(e) => {
            STORE.agentContact.handleAgentConsultFailed(e.detail, STORE.agent.agentId);
        }}
          @agent-consult-transfer-failed="${(e) => {
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    modalType: "dialog",
                    message: getErrorMsgForReasonCode(e.detail.reasonCode) || t("app:interaction.consultTransferFailed"),
                    trackingId: e.detail.trackingId
                }
            });
        }}"
          @agent-ctq-cancelled=${(e) => {
            STORE.agentContact.updateForCTQ(e.detail.interactionId);
        }}
          @agent-ctq-failed=${(e) => {
            STORE.agentContact.updateForCTQ(e.detail.interactionId);
        }}
          @agent-ctq-cancel-failed=${(e) => {
            STORE.agentContact.updateForCTQFailure(e.detail);
        }}
          @ax-media-view-message-recieved=${(e) => {
            if (e.detail.convId && e.detail.actorId !== STORE.agent.agentId) {
                STORE.session.chat.setUnreadMessages(e.detail);
            }
        }}
          @ax-media-view-conv-loaded=${(e) => {
            var _a;
            const taskId = ((_a = STORE.agentContact.getTaskIdFromConvId(e.detail.convId)) === null || _a === void 0 ? void 0 : _a.interactionId) || "";
            STORE.session.chat.deleteUnreadMessages(taskId);
            STORE.session.chat.updateIsConvNotFound(e.detail.convId, false);
        }}
          @ax-media-view-customer-left=${(e) => {
            // updating hasCustomerFlag in chat store for disabling the buttons except end button
            STORE.session.chat.updateHasCustomerLeft(e.detail.convId);
        }}
          @ax-media-view-ws-customer-left=${(e) => {
            //emit desktop level notification for customer left
            STORE.generalNotifications.fireChatEnded(e.detail);
        }}
          @ax-media-view-conv-not-found=${(e) => {
            // updating hasCustomerFlag in chat store for disabling the buttons except end button
            STORE.session.chat.updateIsConvNotFound(e.detail.convId, true);
        }}
          @agent-outbound-failed=${(e) => {
            STORE.agentContact.handleOutBoundFailed(e.detail);
        }}
          @agent-conference-failed=${(e) => {
            STORE.agentContact.handleChatConferenceFailed(e.detail);
            const data = {
                interactionId: "",
                destAgentId: "",
                initiateConferenceChat: false,
                agentId: ""
            };
            STORE.agentContact.updatechatConferenceData(data);
        }}
          @agent-conference-participantJoined=${(e) => {
            STORE.agentContact.handleConfParticipantJoined(e.detail);
        }}
          @ax-update-navigation-warning="${(e) => {
            if (STORE.agentContact.navigationWarning["chatMessages"] !== e.detail["value"]) {
                STORE.agentContact.updateWarnOnNavigation(e.detail);
            }
        }}"
          @ax-reset-warn-on-navigation="${() => {
            if (STORE.agentContact.navigationWarning["chatMessages"]) {
                STORE.agentContact.resetWarningOnNavigation();
            }
        }}"
          @ax-attachment-limit-exceeds-error="${() => {
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    modalType: "dialog",
                    message: t("app:chat.attachmentLimitExceeds")
                }
            });
        }}"
          @agent-outidal-ani-list="${(e) => {
            STORE.agent.updateoutdialAniList(e.detail);
        }}"
          @ax-interaction-recording-paused="${(e) => {
            STORE.agentContact.updateRecordingStatus(e.detail.interactionId, "true");
        }}"
          @ax-interaction-recording-resumed="${(e) => {
            STORE.agentContact.updateRecordingStatus(e.detail.interactionId, "false");
        }}"
          @ax-chat-characters-limit-exceeds-error="${() => {
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    modalType: "dialog",
                    message: t("app:chat.chatCharacterLimitExceeds")
                }
            });
        }}"
          @imi-message-received="${(e) => {
            const newMsgEvent = {
                actorId: e.detail.senderId,
                actorName: e.detail.senderName || e.detail.senderId,
                actorType: e.detail.senderType,
                convId: e.detail.conversationId,
                message: e.detail.isAttachment ? "" : e.detail.message,
                isAttachment: e.detail.isAttachment
            };
            STORE.session.chat.setUnreadMessages(newMsgEvent);
        }}"
          @imi-conversation-loaded="${(e) => {
            var _a;
            STORE.session.chat.setImiWidgetInitState(false);
            const taskId = ((_a = STORE.agentContact.getTaskIdFromConvId(e.detail.conversationId)) === null || _a === void 0 ? void 0 : _a.interactionId) || "";
            STORE.session.chat.deleteUnreadMessages(taskId);
        }}"
          @imi-conversation-loaded-error="${() => {
            STORE.session.chat.setImiWidgetInitState(false);
        }}"
          @imi-livechat-closed="${(e) => {
            STORE.session.chat.updateHasCustomerLeft(e.detail.conversationId);
        }}"
          @imi-services-initialized="${() => {
            STORE.session.chat.setImiServicesInitialized(true);
        }}"
          @agent-contact-cleanup="${(e) => {
            STORE.agentContact.handleContactCleanup(e.detail);
            showContactCleanUpModal(e.detail.trackingId);
        }}"
          @agent-contact-merged="${(e) => {
            if (e.detail.childInteractionId) {
                STORE.agentContact.deleteTask(e.detail.childInteractionId, true);
            }
            STORE.agentContact.updateTask(e.detail);
            STORE.agentContact.selectedTaskChange(e.detail.interactionId);
            STORE.routing.navigateTask(e.detail.interactionId);
        }}"
          @monitoring-offered="${(e) => {
            midcallMonitoringOffered(e.detail.interaction.owner, e.detail.interactionId);
            STORE.agentContact.updateTask(e.detail);
        }}"
          @agent-offer-campaign-reserved="${(e) => {
            STORE.agentContact.updateTask(e.detail.data);
        }}"
          @agent-add-campaign-reserved="${(e) => {
            STORE.agentContact.updateTask(e.detail.data);
        }}"
          @monitoring-started="${(e) => {
            midcallMonitoringStarted(e.detail.interaction.owner, e.detail.interactionId);
            STORE.agentContact.updateTask(e.detail);
            STORE.agentContact.checkBeforeSelectingTask(e.detail.interactionId);
        }}"
          @monitoring-ended="${(e) => {
            midcallMonitoringEnded(e.detail.interactionId);
            STORE.agentContact.deleteAndSelectNextTask(e.detail.interactionId);
            STORE.agentContact.handleAbruptMonitoringEnd();
        }}"
          @monitoring-end-failed="${(e) => {
            const { reasonCode } = e.detail;
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    modalType: "dialog",
                    message: getErrorMsgForReasonCode(reasonCode) || t("app:monitoring.monitoringEndFailed"),
                    trackingId: e.detail.trackingId
                }
            });
        }}"
          @monitoring-held="${(e) => {
            STORE.agentContact.updateHeldUnheldPropsMonitoring(e.detail);
        }}"
          @monitoring-unheld="${(e) => {
            STORE.agentContact.updateHeldUnheldPropsMonitoring(e.detail);
        }}"
          @monitoring-create-failed="${(e) => {
            const { reasonCode } = e.detail;
            if (reasonCode !== RACE_ERROR_REASON_CODE) {
                STORE.session.errorModal.setErrorDetails({
                    detail: {
                        modalType: "dialog",
                        message: getErrorMsgForReasonCode(e.detail.reasonCode) || t("app:monitoring.monitoringStartFailed"),
                        trackingId: e.detail.trackingId
                    }
                });
            }
        }}"
          @monitoring-failed="${(e) => {
            midcallMonitoringFailed(e.detail.interactionId);
            STORE.agentContact.deleteAndSelectNextTask(e.detail.interactionId);
            STORE.session.errorModal.setErrorDetails({
                detail: {
                    modalType: "dialog",
                    htmlMessage: getErrorMsgForReasonCode(e.detail.reasonCode) ||
                        html `
                    <div>
                      ${t("app:monitoring.monitoringFailed")}
                      <ul style="padding-left:14px;">
                        <li>${t("app:monitoring.requestNotAnswered")}</li>
                        <li>${t("app:monitoring.systemError")}</li>
                      </ul>
                    </div>
                  `,
                    trackingId: e.detail.reason !== "MONITORING_FAILED" ? e.detail.trackingId : ""
                }
            });
        }}"
          @agent-monitor-state-changed="${(e) => {
            var _a;
            if (e.detail.eventType === SUPERVISOR_EVENT) {
                bargeInStarted(e.detail.interactionId, e.detail.agentId);
                STORE.agentContact.updateBargeInProps(e.detail);
            }
            else {
                const supervisorName = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.supervisorName;
                triggerBargeInNotification(supervisorName);
                const agentCallMonitoringState = getAgentCallMonitoringStateOnBargeIn(e.detail);
                STORE.agentContact.setAgentCallMonitoringStateDetails(agentCallMonitoringState);
                if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled) {
                    STORE.agentContact.updateInteractionAndMediaObjectForMonitorStateChangeEvent(e.detail);
                }
            }
        }}"
          @contact-callback-failed="${(e) => {
            const { reasonCode, trackingId, interactionId } = e.detail;
            if (STORE.app.featureFlags.isCallbackRetryEnabled) {
                STORE.agentContact.deleteTask(interactionId);
                const errorMessage = contactCallbackRetryModal(reasonCode);
                showErrorModal(errorMessage, trackingId);
            }
        }}"
          @agent-monitor-end="${(e) => {
            var _a, _b;
            const supervisorName = ((_b = (_a = STORE.agentContact) === null || _a === void 0 ? void 0 : _a.agentCallMonitoringState) === null || _b === void 0 ? void 0 : _b.supervisorName) || "";
            if (STORE.agentContact.agentCallMonitoringState &&
                STORE.agentContact.agentCallMonitoringState.monitoringType === BARGE_IN_MONITORING_STATE) {
                triggerLeaveInteractionNotification(supervisorName);
                if (STORE.app.featureFlags.isWxccMultiPartyConfEnabled) {
                    STORE.agentContact.removeSupervisorFromInteractionAndMediaObject(e.detail);
                }
            }
            STORE.agentContact.setAgentCallMonitoringStateDetails({});
        }}"
        ></agentx-react-utility>

        <agentx-wc-shortcut-keys-modal
          @ax-close-shortcut-key-modal=${(e) => {
            STORE.shortcutKeys.updateShortcutKeysModal(e.detail);
        }}
          .isModalOpen=${STORE.shortcutKeys.shortcutKeysModal}
          .isShortcutKeyConflict=${STORE.shortcutKeys.updateKeyboardShortcutConflict}
        ></agentx-wc-shortcut-keys-modal>

        <agentx-webex-wrapper
          .showModal=${STORE.app.webexShowModal}
          .webexEnabled=${STORE.app.webexEnabled}
          .darkMode=${STORE.app.darkMode}
          .minimize=${STORE.app.webexMinimized}
          .agentEmailId=${STORE.agent.agentEmailId}
          .agentId=${STORE.agent.agentId}
          .orgId=${(_b = (_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.orgId) !== null && _b !== void 0 ? _b : ""}
          .webexMeetingTimeStamp=${STORE.app.webexMeetingTimeStamp}
          .isWebexLogoutRequested=${STORE.auth.isWebexLogoutRequested}
          @agentx-webex-wrapper-close=${() => {
            STORE.app.updateWebexShowModal(false);
            localStorage.setItem(WEBEX_SHOW_MODAL, "false");
        }}
          @agentx-webex-minimize-restore=${() => {
            var _a;
            localStorage.setItem(WEBEX_MINIMIZE_MODAL, (_a = (!STORE.app.webexMinimized)) === null || _a === void 0 ? void 0 : _a.toString());
            STORE.app.updateWebexMinimized(!STORE.app.webexMinimized);
        }}
        ></agentx-webex-wrapper>

        ${STORE.dynamic.headless
            ? html `
              <uuip-dynamic-widgets
                .dataProvider=${UTILS_DYNAMIC.storeDataProvider}
                .page=${STORE.dynamic.headless}
                width="0px"
                height="0px"
              >
              </uuip-dynamic-widgets>
            `
            : nothing}
      </div>
    `
    });
};
export default UtilityBlock;
