export const WELL_BEING_BREAK_TITLE = "Ready to pause and recharge?";
export const WELL_BEING_BREAK_MESSAGE = "This break is pre-approved by your organization for your well-being. You deserve it.";
export const LATER = "Later";
export const TAKE_A_BREAK = "Take a break";
export const CALL_DROP_SUMMARY_TITLE = "Call drop summary available";
export const CALL_SUMMARY_MESSAGE = "Customer's last call ended abruptly";
export const SUMMARY_LINK = "View summary";
export const VIRTUAL_AGENT_SUMMARY_TITLE = "Virtual agent summary available";
export const VIRTUAL_AGENT_SUMMARY_MESSAGE = "Here's a summary of the interaction";
export const WELL_BEING_BREAK_ERROR_AVAILABLE = "We encountered an issue setting your status to Available.";
export const WELL_BEING_BREAK_ERROR_IDLE = "We encountered an issue setting your status for the well-being break.";
export const WELL_BEING_BREAK_GENERAL_ERROR = "We couldn't start your well-being break due to a system issue.";
export const WELL_BEING_BREAK_ERROR_TITLE = "Status Update Issue";
