import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { getHostFromUrl, isLocalhost } from "../utils/URLUtils";
import AiAssistantMessageProcessor from "./AiAssistantMessageProcessor";
export var InboundRequestType;
(function (InboundRequestType) {
    InboundRequestType["ShowCallDropAiNotification"] = "ShowCallDropAiNotification";
    InboundRequestType["ShowVirtualAgentSummaryNotification"] = "ShowVirtualAgentSummaryNotification";
    InboundRequestType["RemoveAiSummaryNotifications"] = "RemoveAiSummaryNotifications";
    InboundRequestType["ShowWellnessBreakNotification"] = "ShowWellnessBreakNotification";
    InboundRequestType["RemoveWellnessBreakNotifications"] = "RemoveWellnessBreakNotifications";
    InboundRequestType["ShowWellnessBreakErrorNotification"] = "ShowWellnessBreakErrorNotification";
})(InboundRequestType || (InboundRequestType = {}));
export var OutboundRequestType;
(function (OutboundRequestType) {
    OutboundRequestType["OpenAiAssistant"] = "OpenAiAssistant";
    OutboundRequestType["AcceptWellnessBreak"] = "AcceptWellnessBreak";
    OutboundRequestType["RejectWellnessBreak"] = "RejectWellnessBreak";
    OutboundRequestType["DismissWellnessBreak"] = "DismissWellnessBreak";
    OutboundRequestType["TimeoutWellnessBreak"] = "TimeoutWellnessBreak";
})(OutboundRequestType || (OutboundRequestType = {}));
export var InboundAiAssistantError;
(function (InboundAiAssistantError) {
    InboundAiAssistantError[InboundAiAssistantError["None"] = 0] = "None";
    InboundAiAssistantError[InboundAiAssistantError["ChangeStateToResetError"] = 1] = "ChangeStateToResetError";
    InboundAiAssistantError[InboundAiAssistantError["ChangeStateToAvailableError"] = 2] = "ChangeStateToAvailableError";
    InboundAiAssistantError[InboundAiAssistantError["GeneralError"] = 3] = "GeneralError";
})(InboundAiAssistantError || (InboundAiAssistantError = {}));
const logger = createLogger("[MessageHandler]");
class MessageHandler {
    constructor(messageProcessors = {}) {
        this.aiAssistantMessageProcessor =
            messageProcessors.aiAssistantMessageProcessor || new AiAssistantMessageProcessor();
        window.addEventListener("message", this.receiveMessage.bind(this));
    }
    postMessage(outboundRequestType) {
        var _a;
        const targetOrigin = (_a = STORE.envVaribles.serviceUrls.hostUrl) !== null && _a !== void 0 ? _a : "";
        if (targetOrigin) {
            logger.info("Posting a message from wxcc-app:", outboundRequestType);
            const outboundMessage = {
                type: "wxcc-app",
                config: {
                    requestType: outboundRequestType
                }
            };
            window.postMessage(outboundMessage, isLocalhost() ? "*" : targetOrigin);
        }
    }
    receiveMessage(event) {
        if (!this.isCommunicationPermitted(event.origin)) {
            logger.error("Host URL does not match event.origin. Unable to receive message");
            return;
        }
        const { type, config } = event.data;
        if (type === "ai-assistant-app") {
            const { requestType, errorType } = config;
            logger.info("Received a message from ai-assistant-app:", requestType);
            switch (requestType) {
                case InboundRequestType.ShowCallDropAiNotification:
                case InboundRequestType.ShowVirtualAgentSummaryNotification:
                    this.aiAssistantMessageProcessor.process({
                        inboundRequestType: requestType,
                        notificationLinkHandlerCallback: () => {
                            this.postMessage(OutboundRequestType.OpenAiAssistant);
                        }
                    });
                    break;
                case InboundRequestType.RemoveAiSummaryNotifications:
                case InboundRequestType.RemoveWellnessBreakNotifications:
                    this.aiAssistantMessageProcessor.process({
                        inboundRequestType: requestType
                    });
                    break;
                case InboundRequestType.ShowWellnessBreakNotification:
                    this.aiAssistantMessageProcessor.process({
                        inboundRequestType: requestType,
                        notificationAcceptActionCallback: () => {
                            this.postMessage(OutboundRequestType.AcceptWellnessBreak);
                        },
                        notificationRejectActionCallback: () => {
                            this.postMessage(OutboundRequestType.RejectWellnessBreak);
                        },
                        notificationDismissActionCallback: () => {
                            this.postMessage(OutboundRequestType.DismissWellnessBreak);
                        },
                        notificationTimeoutCallback: () => {
                            this.postMessage(OutboundRequestType.TimeoutWellnessBreak);
                        }
                    });
                    break;
                case InboundRequestType.ShowWellnessBreakErrorNotification:
                    this.aiAssistantMessageProcessor.process({ inboundRequestType: requestType, errorType });
            }
        }
    }
    isCommunicationPermitted(origin) {
        var _a;
        const hostUrl = (_a = STORE.envVaribles.serviceUrls.hostUrl) !== null && _a !== void 0 ? _a : "";
        const hostUrlHostName = getHostFromUrl(hostUrl);
        const originHostName = getHostFromUrl(origin);
        return (hostUrl && (originHostName === null || originHostName === void 0 ? void 0 : originHostName.endsWith(hostUrlHostName))) || isLocalhost();
    }
}
export default MessageHandler;
